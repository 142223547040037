import React, { FC } from "react";
import { Input } from "antd";

type Props = {
  type?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  value: string | number;
  blurEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  max?: number;
};

const CustomInput: FC<Props> = (props) => {
  return (
    <Input
      disabled={props.disabled}
      type={props.type}
      name={props.name}
      className={props.className}
      placeholder={props.placeholder}
      value={props.value}
      onBlur={props.blurEvent}
      onChange={props.changeEvent}
      maxLength={props.max}
    />
  );
};

export default CustomInput;
