import http from "../../http";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import {
  questionResponseFormat,
  questionSubmitFormat,
  logicJumpFormat,
} from "../../helpers/question";
import { Dispatch } from "redux";
import { setAppLoader } from "../reducers/loaderSlice";
import { getStudyId } from "../../helpers/study";

const downloadFile = async (myData: any) => {
  const fileName = myData.name;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export questionnaire
export const exportQuestionnaire = (qid: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAppLoader(true));
    const res = await http.get(`/questionnaires/${qid}/questions`);
    await downloadFile(res.data.data);
    dispatch(setAppLoader(false));
  } catch (err) {
    dispatch(setAppLoader(false));
    errorToastMessage(err as Error);
  }
};

//import questionnaire
export const importQuestionnaire =
  (Qbody: any, callback: Function) => (dispatch: Dispatch<any>) => {
    if (Qbody.hasOwnProperty("questions")) {
      let body = constructBody(Qbody);
      dispatch(setAppLoader(true));
      http
        .post("/questionnaires", body)
        .then((res) => {
          dispatch(createNewQuestionBuilder(Qbody, res.data.data.id, callback));
        })
        .catch((err) => {
          dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
        });
    } else {
      toastMessage(
        "warning",
        "Please use Mahalo format questionnaire JSON file"
      );
    }
  };

const createNewQuestionBuilder =
  (Qbody: any, qid: any, callback: Function) =>
  async (dispatch: Dispatch<any>) => {
    let questions = await questionResponseFormat(
      Qbody.questions,
      Qbody.logicJumps
    );

    let ques = await questionSubmitFormat(questions);
    let logicJumps = await logicJumpFormat(questions);

    const formObject = {
      id: qid,
      name: Qbody.name,
      questions: ques,
      logicJumps: logicJumps,
      status: "draft",
    };
    http
      .post(`/questionnaires/${formObject.id}/build`, formObject)
      .then(({ data }) => {
        toastMessage("success", data.message);
        callback();
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

const constructBody = (values: any) => {
  let obj = {
    name: values.name,
    imageUrl: values.imageUrl !== null ? values.imageUrl : "",
    description: values.description,
    studyId: getStudyId(),
    category: values.category,
  };
  if (obj.imageUrl === "") {
    delete obj.imageUrl;
  }
  if (obj.description === "" || obj.description === null) {
    delete obj.description;
  }
  return obj;
};
