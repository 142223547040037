import React from "react";

type Props = {
  phases: any[];
  setSelectedPhase: Function;
  selectedPhase: any;
  selected: any;
  setSelected: Function;
};

const FixedSider: React.FC<Props> = ({
  phases,
  setSelectedPhase,
  selectedPhase,
  setSelected,
  selected,
}) => {
  return (
    <div className="survey-sider fixed">
      {phases.map((phase) => {
        const selection = () => {
          setSelectedPhase((prev: any) => {
            return prev === phase?.id ? null : phase.id;
          });
        };
        return (
          <div className="phase-container" key={phase.id} onClick={selection}>
            <div className="block">
              <div className="color-primary font-m fw-semibold mb-2">
                {phase.name}
              </div>
              <div
                className={
                  phase.completed
                    ? "phase-progress completed"
                    : selectedPhase === phase.id || phase.inprogress
                    ? "phase-progress in-progress"
                    : "phase-progress not-started"
                }
              >
                {phase.completed
                  ? "Completed"
                  : selectedPhase === phase.id || phase.inprogress
                  ? "In progress"
                  : "Not Started"}
              </div>
            </div>
            {selectedPhase === phase.id &&
              phase?.steps?.map((visit: any) => {
                const isSelected =
                  visit.id === selected?.id &&
                  visit.phaseId === selected?.phaseId;
                return (
                  <div
                    key={visit.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelected(visit);
                    }}
                    className={
                      isSelected
                        ? "selected-block block ques-block"
                        : "block ques-block"
                    }
                  >
                    <div>
                      <div className="form-name">{visit.name}</div>
                      <div
                        className={
                          visit.completed
                            ? "form-progress completed"
                            : isSelected || visit.inprogress
                            ? "form-progress inprogress"
                            : "form-progress"
                        }
                      >
                        {visit.completed
                          ? visit?.dataVerified
                            ? "Data Verified"
                            : "Completed"
                          : isSelected || visit.inprogress
                          ? "In progress"
                          : "Not started"}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(FixedSider);
