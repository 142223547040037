import { Modal, Input, Button } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

type Props = {
  show: boolean;
  value: any;
  isDraft: boolean;
  successCallback: Function;
  failureCallback: Function;
};

const GCPSubmissionModal = (props: Props) => {
  const [reason, setReason] = useState("");
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (event: any) => {
    setReason(event.target.value);
  };

  const submitHandler = async () => {
    try {
      if (!reason.trim()) {
        toastMessage("warning", "Reason cannot be empty");
        return;
      }
      dispatch(setAppLoader(true));
      props.successCallback(props.value, reason, props.isDraft);
      closeHandler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const revertChanges = () => {
    props.failureCallback();
    closeHandler();
  };

  return (
    <Modal
      title={"CRF submission"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="fw-medium font-m text-prime mb-4">
          This study is adhering to Good Clinical Practice (GCP)!
        </div>
        <div className="fw-medium font-m text-prime">
          Please, supply a reason for changing this field’s value
        </div>
        <Input
          className="singer-input"
          value={reason}
          placeholder="Enter reason here..."
          onChange={changeHandler}
        />
      </div>
      <div className="d-flex align-items-center mt-4">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={revertChanges}
        >
          <span className="fw-semibold font-sm">Cancel Changes</span>
        </Button>
      </div>
    </Modal>
  );
};

export default GCPSubmissionModal;
