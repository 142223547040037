import React, { useEffect, useMemo, useState } from "react";
import { Button, Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import { setAdminSearchTerm } from "../../redux/reducers/administratorSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { getBERoleName } from "../../constants/roles";
import { PlusIcon } from "@heroicons/react/solid";
import { modifyAdminAccess } from "../../helpers/roles";

type Props = {
  type: string;
};

const AdministratorHeader: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.administrator.searchTerm);
  const role = useAppSelector((state) => state.user.role);
  const [search, setSearch] = useState<string>("");
  const [adminAccess] = useState(modifyAdminAccess(role));

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(() => {
    return debounce((val) => {
      dispatch(setAdminSearchTerm(val));
    }, 500);
  }, [dispatch]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    dispatch(
      setModalDetails({
        type: `ADD_ADMIN_MODAL`,
        modalProps: {
          show: true,
          header: type,
          role: getBERoleName(type),
        },
      })
    );
  };

  return (
    <div className="content-header">
      <div className="font-m fw-semibold font-l me-5 text-secondary">
        Administrators
      </div>
      <Input
        value={search}
        onChange={changeHandler}
        style={{ width: "300px" }}
        placeholder="Search..."
        className="singer-input"
      />
      <span style={{ marginLeft: "auto" }}></span>
      {adminAccess && (
        <Button
          onClick={handleButtonClick}
          className="d-flex align-items-center me-4"
          style={{ height: "40px", borderRadius: "12px" }}
          type="primary"
        >
          <PlusIcon className="hero-small-icon me-2" />
          <span className="fw-semibold font-sm">Add {type}</span>
        </Button>
      )}
    </div>
  );
};

export default AdministratorHeader;
