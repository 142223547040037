export const canAddParticipant = (role: string) => {
  return ["system_admin"].includes(role);
};

export const hasBlindingAccess = (role: string) => {
  return ["system_admin"].includes(role);
};

export const canViewCRFs = (role: string) => {
  return [
    "system_admin",
    "research_coordinator",
    "monitor",
    "observer",
    "researcher",
  ].includes(role);
};

export const canEditCRFs = (role: string) => {
  return ["system_admin", "research_coordinator", "researcher"].includes(role);
};

export const canSignOffCRFs = (role: string) => {
  return ["researcher"].includes(role);
};

export const canReconcileCRFs = (role: string) => {
  return ["monitor"].includes(role);
};

export const canEditAdverseEvents = (role: string) => {
  return ["system_admin", "research_coordinator", "researcher"].includes(role);
};

export const canEditMedication = (role: string) => {
  return ["system_admin", "research_coordinator", "researcher"].includes(role);
};

export const canEditMedicalHistory = (role: string) => {
  return ["system_admin", "research_coordinator", "researcher"].includes(role);
};

export const canAmendStudy = (role: string) => {
  return ["system_admin"].includes(role);
};

export const canExportData = (role: string) => {
  return ["system_admin"].includes(role);
};

export const AmendQuestions = ["system_admin"];

export const canAmendQuestions = (role: string) => {
  return AmendQuestions.includes(role);
};

export const modifyAdminAccess = (role: string) => {
  return ["system_admin"].includes(role);
};

export const canAmendQueries = (role: string) => {
  return [
    "system_admin",
    "research_coordinator",
    "monitor",
    "observer",
    "researcher",
  ].includes(role);
};

export const hasAuditAccess = (role: string) => {
  return ["system_admin"].includes(role);
};

export const modifyStudySettings = (role: string) => {
  return ["system_admin"].includes(role);
};

export const canLockParticipant = (role: string) => {
  return ["system_admin", "research_coordinator"].includes(role);
};

// Unused Role helpers

export const canAmendParticipantDetails = (role: string) => {
  return [
    "system_admin",
    "research_coordinator",
    "monitor",
    "observer",
    "researcher",
  ].includes(role);
};

export const hasAdminAccess = (role: string) => {
  return ["system_admin"].includes(role);
};

export const canAmendAppointments = (role: string) => {
  return ["system_admin", "research_coordinator"].includes(role);
};

export const canAddExercise = (role: string) => {
  return ["system_admin"].includes(role);
};
