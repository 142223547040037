import { CloseOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const ViewImage: React.FC<any> = ({ attachment }) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(hideModal());
  };
  return (
    <div className="backdrop" onClick={closeHandler}>
      <CloseOutlined className="backdrop-close" onClick={closeHandler} />
      <img src={attachment.href} className="view-image" alt="detailed view" />
    </div>
  );
};

export default ViewImage;
