import { Route, Routes } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";
import {
  AuthenticatedRoute,
  UnAuthenticatedRoute,
} from "../Common/RouteComponents";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Login from "../Login/Login";
import ResetPassword from "../ResetPassword/ResetPassword";
import SetPassword from "../SetPassword/SetPassword";
import SmsSecurity from "../SmsSecurity/SmsSecurity";
import VerifyEmail from "../VerifyEmail/VerifyEmail";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <UnAuthenticatedRoute>
            <Login />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <UnAuthenticatedRoute>
            <ForgotPassword />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/sms-security"
        element={
          <UnAuthenticatedRoute>
            <SmsSecurity />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/verify-email"
        element={
          <UnAuthenticatedRoute>
            <VerifyEmail />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/set-password/:token"
        element={
          <UnAuthenticatedRoute>
            <SetPassword />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <UnAuthenticatedRoute>
            <ResetPassword />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/change-password"
        element={
          <AuthenticatedRoute>
            <ChangePassword />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};

export default AuthRoutes;
