import React, { useState } from "react";
import { useDrop } from "react-dnd";
import TextChoice from "./TextChoice";
import { choices } from "./questionTypes";

const acceptedItems = choices.map((choice) => choice.type);

type Props = {
  questions: any;
  handleDrop: Function;
  duplicate: Function;
  duplicationGroupQ: Function;
  delete: Function;
  setInnerFields: Function;
  moveItem: Function;
  handleSequenceDrop: Function;
  questionnaireStatus: any;
  questionList?: any;
  qid?: string;
  handleSettings: Function;
  showChinese: boolean;
};

type DItem = {
  type: string;
};

const DropArea = (props: Props) => {
  const [childDrop, setChildDrop] = useState(false);

  const onDrop = (type: string, index: number) => {
    setChildDrop(true);
    props.handleDrop(type, index);
  };

  const [{ canDrop }, drop] = useDrop({
    accept: acceptedItems,
    drop: (dropItem: DItem) => {
      if (!childDrop) {
        props.handleDrop(dropItem.type, false);
      }
      setChildDrop(false);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const { questions } = props;

  return (
    <div
      ref={drop}
      className="question-list"
      style={{ border: canDrop ? "2px dashed #dbdbdb" : "none" }}
    >
      {questions.map((question: any, index: number) => (
        <TextChoice
          key={question.id}
          index={index}
          choice={question}
          accepted={acceptedItems}
          handleDrop={onDrop}
          duplicate={props.duplicate}
          duplicationGroupQ={props.duplicationGroupQ}
          delete={props.delete}
          setInnerFields={props.setInnerFields}
          questions={questions}
          moveItem={props.moveItem}
          handleSequenceDrop={props.handleSequenceDrop}
          questionnaireStatus={props.questionnaireStatus}
          qid={props.qid}
          handleSettings={props.handleSettings}
          showChinese={props.showChinese}
        />
      ))}
    </div>
  );
};

export default DropArea;
