import React, { useState } from "react";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Button, Select, Spin } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { getStudyId } from "../../../helpers/study";
import { debounce } from "lodash";

type Props = {
  show: boolean;
  phaseName: string;
  phaseId: string;
  callback: Function;
};

const AddPhaseForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const [forms, setForms] = useState<any[]>([]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async () => {
    try {
      if (forms.length === 0) {
        toastMessage("warning", "Select atleast one questionnaire");
        return;
      }
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      await http.post(`/study/${studyId}/phase/${props.phaseId}/step`, {
        addIds: forms,
        removeIds: [],
      });
      closeHandler();
      props.callback();
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

  const [options, setOptions] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(false);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setOptions([]);
        if (!value) {
          return;
        }
        setFetching(true);
        const res = await http.get(
          `/questionnaires?page=1&size=20&search=${value}`
        );
        const questionnaires = res.data.data.questionnaires.map(
          (question: any) => {
            return {
              label: question.name,
              value: question.id,
            };
          }
        );
        setOptions(questionnaires);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setOptions]);

  return (
    <Modal
      title={"Add Forms to " + props.phaseName}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group">
            <div className="text-secondary font-m fw-medium mb-1">
              Questionnaires
            </div>
            <Select
              showSearch
              size="large"
              filterOption={false}
              value={forms}
              mode="multiple"
              placeholder="Start typing to search..."
              onChange={(val) => setForms(val)}
              onSearch={debounceFetcher}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              options={options}
              className="mt-2 custom-select-field"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default AddPhaseForm;
