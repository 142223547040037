import React, { useEffect, useState, useRef, useCallback } from "react";
import { Avatar, Table, Dropdown, Menu } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import {
  fetchAdministratorDetails,
  modifyAdminDetails,
  resendAdminInvite,
} from "../../redux/actions/adminAction";
import { SortOrder } from "antd/lib/table/interface";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { getImageInitials } from "../../helpers/user";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { modifyAdminAccess } from "../../helpers/roles";

const { Column } = Table;

type Props = {
  type: string;
};

const AdminListView: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any | null>(null);
  const [filter, setFilter] = useState("");
  const role = useAppSelector((state) => state.user.role);
  const [adminAccess] = useState(modifyAdminAccess(role));

  const { searchTerm, adminCount, adminUsers, toggleLoader } = useAppSelector(
    (state) => state.administrator
  );

  const toggleRef = useRef(toggleLoader);

  const fetchData = useCallback(
    async (search, type) => {
      try {
        await dispatch(fetchAdministratorDetails(type, search, 1, "", null));
        setCurrentPage(1);
        setFilter("");
        setSort(null);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setCurrentPage, setFilter, setSort]
  );

  useEffect(() => {
    fetchData(searchTerm, type);
  }, [searchTerm, fetchData, type]);

  useEffect(() => {
    if (toggleLoader !== toggleRef.current) {
      fetchData(searchTerm, type);
      toggleRef.current = toggleLoader;
    }
  }, [searchTerm, fetchData, toggleLoader, type]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    let page = 1;
    let filter = "";
    if (filters.status) {
      setFilter(filters.status[0]);
      filter = filters.status[0];
    } else {
      setFilter("");
    }
    let sort = null;
    if (sorters.order) {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "firstName",
        //hardcoded because BE accepts only firstName as param
      };
      setSort(sort);
    } else {
      setSort(null);
    }
    if (pagination.current) {
      setCurrentPage(pagination.current);
      page = pagination.current;
    }
    try {
      await dispatch(
        fetchAdministratorDetails(type, searchTerm, page, filter, sort)
      );
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const modifyUser = async (data: any) => {
    try {
      const obj = {
        status: data.status === "inactive" ? "active" : "inactive",
      };
      await dispatch(modifyAdminDetails(obj, data.id));
      await dispatch(
        fetchAdministratorDetails(type, searchTerm, currentPage, filter, sort)
      );
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const resendInvite = async (data: any) => {
    try {
      await dispatch(resendAdminInvite(data.id));
      await dispatch(
        fetchAdministratorDetails(type, searchTerm, currentPage, filter, sort)
      );
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  // const viewAppointment = (data: any) => {
  //   const userId = localStorage.getItem("userId");
  //   if (userId === data.id) {
  //     window.open(`/appointments`, "_blank");
  //   } else {
  //     window.open(
  //       `/appointments?name=${data.firstName + " " + data.lastName}&id=${
  //         data.id
  //       }`,
  //       "_blank"
  //     );
  //   }
  // };

  const menu = (data: any) => (
    <Menu>
      {adminAccess && (
        <Menu.Item key="0" onClick={() => modifyUser(data)}>
          {data.status === "inactive" ? "Activate" : "Deactivate"}
        </Menu.Item>
      )}
      {adminAccess && !data.emailVerified && (
        <Menu.Item key="2" onClick={() => resendInvite(data)}>
          Resend Invite
        </Menu.Item>
      )}
      {/* <Menu.Item key="1" onClick={() => viewAppointment(data)}>
        View Appointment
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div className="modules-wrapper">
      <Table
        dataSource={adminUsers}
        showSorterTooltip={false}
        className="singer-custom-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          current: currentPage,
          total: adminCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: PaginationText,
          className: "singer-table-pagination",
          position: ["bottomLeft"],
          itemRender: CustomPagination,
        }}
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          sorter={true}
          width="30%"
          sortOrder={
            sort?.sortBy === "firstName"
              ? sort.orderBy === "asc"
                ? ("ascend" as SortOrder)
                : ("descend" as SortOrder)
              : undefined
          }
          render={(_1: any, record: any) => {
            return (
              <div className="d-flex">
                {record.profileImage ? (
                  <Avatar
                    shape="circle"
                    size="large"
                    src={record.profileImage}
                  />
                ) : (
                  <Avatar shape="circle" size="large">
                    {getImageInitials(record.firstName, record.lastName)}
                  </Avatar>
                )}
                <div className="ms-3">
                  <p className="text-s m-0 fw-medium">
                    {record.firstName + " " + record.lastName}
                  </p>
                  <span className="text-muted font-xs">{record.email}</span>
                </div>
              </div>
            );
          }}
        />
        <Column title="Created Date" dataIndex="createdAt" key="createdAt" />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          filters={[
            {
              text: "Active",
              value: "active",
            },
            {
              text: "Inactive",
              value: "inactive",
            },
          ]}
          filterMultiple={false}
          filteredValue={filter ? [filter] : null}
          render={(text: string) => (
            <span
              className={
                text === "active" ? "active-tag badge" : "inactive-tag badge"
              }
            >
              {text}
            </span>
          )}
        />
        {adminAccess && (
          <Column
            title=""
            width="20px"
            render={(_1, record: any) => {
              return (
                <span onClick={(e) => e.stopPropagation()}>
                  <Dropdown
                    overlay={menu(record)}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <DotsVerticalIcon
                      className="hero-icon cp"
                      style={{ color: "#71717A", marginLeft: "auto" }}
                    />
                  </Dropdown>
                </span>
              );
            }}
          />
        )}
      </Table>
    </div>
  );
};

export default AdminListView;
