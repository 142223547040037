// question-group
import React, { useState } from "react";
import GroupChoiceField from "./GroupChoice";
import { fieldMap } from "./questionTypes";
import { v4 as uuid } from "uuid";

type Props = {
  group: any;
  accepted: string[];
  index: number;
  setInnerFields: Function;
  handleDrop: Function;
  duplicationGroupQ: Function;
  moveItem: Function;
  handleSequenceDrop: Function;
  questions: any;
  questionnaireStatus: any;
  qid?: string;
  handleSettings: Function;
  showChinese: boolean;
};

const QuestionGroup = (props: Props) => {
  const [subKey, setSubKey] = useState<number>(2);

  const { group } = props;

  const duplicateQuestionGroupField = (itemIndex: number, type: string) => {
    const prev = group.questions.slice(0, itemIndex + 1);
    const next = group.questions.slice(itemIndex + 1, group.questions.length);
    const newItem = { ...fieldMap(type), key: `${group.key}.${subKey}` };
    const newQuestions = [...prev, newItem, ...next].map((q) => ({
      ...q,
      type,
    }));
    const newGroup = { ...group, questions: newQuestions };
    props.setInnerFields(props.index, newGroup);
    setSubKey(subKey + 1);
  };

  const deleteQuestionGroupField = (itemIndex: number) => {
    const newQuestions = group.questions.filter(
      (_: any, i: number) => i !== itemIndex
    );
    const newGroup = { ...group, questions: newQuestions };
    props.setInnerFields(props.index, newGroup);
  };

  const setQuestionGroupFields = (index: number, newField: any) => {
    const prev = group.questions.slice(0, index);
    const next = group.questions.slice(index + 1, group.questions.length);
    const newQuestions = [...prev, newField, ...next];
    const newGroup = { ...group, questions: newQuestions };
    props.setInnerFields(props.index, newGroup);
  };

  const addQuestionToGroup = () => {
    const itemPosition = group.questions.length + 1;
    const newGroup = {
      ...group,
      questions: [
        ...group.questions,
        {
          ...fieldMap("short_text"),
          type: "short_text",
          position: itemPosition,
          id: uuid(),
        },
      ],
    };
    props.setInnerFields(props.index, newGroup);
    setSubKey(subKey + 1);
  };

  const handleSettings = (subIndex: number) => {
    props.handleSettings({
      index: props.index,
      subIndex: subIndex,
    });
  };

  return (
    <div className="question-group">
      {group.questions.map((groupItem: any, qIndex: number) => (
        <GroupChoiceField
          key={groupItem.id}
          index={qIndex}
          orginalGroupQs={group}
          originalGroupIndex={props.index}
          choice={groupItem}
          accepted={props.accepted}
          handleDrop={props.handleDrop}
          duplicate={duplicateQuestionGroupField}
          delete={deleteQuestionGroupField}
          setInnerFields={setQuestionGroupFields}
          questionnaireStatus={props.questionnaireStatus}
          duplicationGroupQ={props.duplicationGroupQ}
          chooseField
          moveItem={props.moveItem}
          handleSequenceDrop={props.handleSequenceDrop}
          handleSettings={handleSettings}
          showChinese={props.showChinese}
        />
      ))}
      <p className="add-question cp" onClick={addQuestionToGroup}>
        + Add Question
      </p>
    </div>
  );
};

export default QuestionGroup;
