import { Button, DatePicker, Input, Select } from "antd";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

type Props = {
  question: any;
  modifyQuestion: any;
  showChinese: boolean;
};

const GridLogic: React.FC<Props> = ({
  question,
  modifyQuestion,
  showChinese,
}) => {
  const { properties } = question;
  const [gridPreview, setGridPreview] = useState(false);

  const onRowChange = (index: number, event: any, key: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        rows: question.properties.rows.map((row: any, idx: number) => {
          return index === idx ? { ...row, [key]: event.target.value } : row;
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const onColChange = (index: number, event: any, key: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          return index === idx ? { ...col, [key]: event.target.value } : col;
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const onColumnType = (index: number, value: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (index === idx) {
            if (value === "checkbox") {
              return { ...col, type: value, options: [""] };
            } else {
              return { type: value, label: col.label, label_ch: col.label_ch };
            }
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const addField = (type: "row" | "column") => {
    if (type === "row") {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          rows: [...question.properties.rows, { label: "" }],
        },
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          columns: [...question.properties.columns, { type: "short_text" }],
        },
      };
      modifyQuestion(newQuestion);
    }
  };

  const deleteField = (type: "row" | "column", index: number) => {
    if (type === "row") {
      if (question.properties.rows.length > 1) {
        const newQuestion = {
          ...question,
          properties: {
            ...question.properties,
            rows: question.properties.rows.filter(
              (_1: any, idx: number) => idx !== index
            ),
          },
        };
        modifyQuestion(newQuestion);
      }
    } else {
      if (question.properties.columns.length > 1) {
        const newQuestion = {
          ...question,
          properties: {
            ...question.properties,
            columns: question.properties.columns.filter(
              (_1: any, idx: number) => idx !== index
            ),
          },
        };
        modifyQuestion(newQuestion);
      }
    }
  };

  const addOption = (index: number) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (index === idx) {
            return { ...col, options: [...col.options, ""] };
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const deleteOption = (optionIndex: number, columnIndex: number) => {
    const options = question.properties.columns[columnIndex].options;
    if (options.length > 1) {
      const newOption = options.filter(
        (_: any, index: number) => index !== optionIndex
      );
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          columns: question.properties.columns.map((col: any, idx: number) => {
            if (columnIndex === idx) {
              return { ...col, options: newOption };
            } else {
              return col;
            }
          }),
        },
      };
      modifyQuestion(newQuestion);
    }
  };

  const editOption = (optionIndex: number, columnIndex: number, event: any) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (columnIndex === idx) {
            return {
              ...col,
              options: col.options.map((opt: any, index: number) =>
                index !== optionIndex ? opt : event.target.value
              ),
            };
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const toggleGridPreview = () => {
    setGridPreview((prev) => !prev);
  };

  return gridPreview ? (
    <GridPreview
      rows={properties.rows}
      columns={properties.columns}
      toggleGridPreview={toggleGridPreview}
      showChinese={showChinese}
    />
  ) : (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-secondary font-ml fw-semibold mb-3">Rows</div>
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          onClick={toggleGridPreview}
        >
          <span className="fw-semibold font-sm">Preview</span>
        </Button>
      </div>
      <div>
        {properties.rows.map((row: any, index: number) => {
          return (
            <div
              className="d-flex mb-4"
              style={{ alignItems: "flex-end" }}
              key={"row" + index}
            >
              <div style={{ flex: 1, minWidth: "0px" }}>
                <div className="text-secondary font-m fw-medium mb-1">
                  {"Row label " + (index + 1)}
                </div>
                <Input
                  className="singer-input"
                  placeholder="Row Label"
                  value={row.label}
                  onChange={(e) => onRowChange(index, e, "label")}
                />
                {showChinese && (
                  <Input
                    className="singer-input mt-2"
                    placeholder="Row Label (Chinese)"
                    value={row.label_ch}
                    onChange={(e) => onRowChange(index, e, "label_ch")}
                  />
                )}
              </div>
              <div className="ms-3 mb-3">
                <PlusIcon
                  className="large-hero-icon text-gray cp"
                  onClick={() => addField("row")}
                />
                <TrashIcon
                  className="large-hero-icon text-red cp ms-3"
                  onClick={() => deleteField("row", index)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-secondary font-ml fw-semibold mb-3">Columns</div>
      <div>
        {properties.columns.map((column: any, index: number) => {
          return (
            <div className="branch-container mb-4" key={"col" + index}>
              <div className="d-flex mb-2" style={{ alignItems: "flex-end" }}>
                <div style={{ flex: 1, minWidth: "0px" }}>
                  <div className="text-secondary font-m fw-medium mb-1">
                    {"Column label " + (index + 1)}
                  </div>
                  <Input
                    className="singer-input"
                    placeholder="Column Label"
                    value={column.label}
                    onChange={(e) => onColChange(index, e, "label")}
                  />
                  {showChinese && (
                    <Input
                      className="singer-input mt-2"
                      placeholder="Column Label (Chinese)"
                      value={column.label_ch}
                      onChange={(e) => onColChange(index, e, "label_ch")}
                    />
                  )}
                </div>
                <div className="ms-3 mb-3">
                  <PlusIcon
                    className="large-hero-icon text-gray cp"
                    onClick={() => addField("column")}
                  />
                  <TrashIcon
                    className="large-hero-icon text-red cp ms-3"
                    onClick={() => deleteField("column", index)}
                  />
                </div>
              </div>
              <div style={{ flex: 1, minWidth: "0px" }} className="mb-1">
                <div className="text-secondary font-m fw-medium mb-1">
                  {"Column Type " + (index + 1)}
                </div>
                <Select
                  style={{ width: "100%" }}
                  value={column.type}
                  onChange={(val) => onColumnType(index, val)}
                  className="response-choice-selector"
                  size="large"
                >
                  <Select.Option value="short_text">Short Text</Select.Option>
                  <Select.Option value="number">Number</Select.Option>
                  <Select.Option value="date">Calendar</Select.Option>
                  <Select.Option value="checkbox">Checkbox</Select.Option>
                  <Select.Option value="score_input">Score Input</Select.Option>
                </Select>
              </div>
              {column.type === "checkbox" &&
                column.options.map((opt: any, idx: number) => {
                  return (
                    <div
                      className="d-flex mb-2"
                      style={{ alignItems: "flex-end" }}
                      key={"opt" + idx}
                    >
                      <div style={{ flex: 1, minWidth: "0px" }}>
                        <div className="text-secondary font-m fw-medium mb-1">
                          {"Option " + (idx + 1)}
                        </div>
                        <Input
                          className="singer-input"
                          placeholder="Option Label"
                          value={opt}
                          onChange={(e) => editOption(idx, index, e)}
                        />
                      </div>
                      <div className="ms-3 mb-2">
                        <PlusIcon
                          className="large-hero-icon text-gray cp"
                          onClick={() => addOption(index)}
                        />
                        <TrashIcon
                          className="large-hero-icon text-red cp ms-3"
                          onClick={() => deleteOption(idx, index)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const GridPreview: React.FC<any> = ({
  columns,
  rows,
  toggleGridPreview,
  showChinese,
}) => {
  const [chinese, setChinese] = useState(false);

  const toggleChinese = () => {
    setChinese((prev) => !prev);
  };

  const renderCol = (column: any) => {
    switch (column.type) {
      case "short_text":
        return <Input className="singer-input" />;
      case "number":
        return <Input className="singer-input" type="number" />;
      case "date":
        return <DatePicker className="logic-jump-datepicker" />;
      case "checkbox":
        return (
          <Select
            className="response-choice-selector"
            size="large"
            style={{ textAlign: "left" }}
          >
            {column.options.map((opt: any, index: number) => {
              let text = "";
              if (showChinese && opt) {
                const split = opt.split("|");
                if (chinese && split[1]) {
                  text = split[1];
                } else {
                  text = split[0];
                }
              } else {
                text = opt || "Option " + (index + 1);
              }
              return (
                <Select.Option key={"opt" + index} value={opt}>
                  {text}
                </Select.Option>
              );
            })}
          </Select>
        );
      case "score_input":
        return <Input className="singer-input" type="number" />;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="text-secondary font-ml fw-semibold mb-3">Preview</div>
        <span>
          {showChinese && (
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              onClick={toggleChinese}
              className="me-2"
            >
              <span className="fw-semibold font-sm">
                {chinese ? "Show English" : "Show Chinese"}
              </span>
            </Button>
          )}
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={toggleGridPreview}
          >
            <span className="fw-semibold font-sm">Exit Preview</span>
          </Button>
        </span>
      </div>
      <table className="grid-preview">
        <tr>
          <th></th>
          {columns.map((col: any, idx: number) => {
            return (
              <th key={idx}>
                {(chinese ? col.label_ch || col.label : col.label) ||
                  "Column " + (idx + 1)}
              </th>
            );
          })}
        </tr>
        {rows.map((row: any, idx: number) => {
          return (
            <tr key={idx}>
              <td key={"row" + idx}>
                {(chinese ? row.label_ch || row.label : row.label) ||
                  "Row " + (idx + 1)}
              </td>
              {columns.map((col: any, idx: number) => {
                return <td key={"col" + idx}>{renderCol(col)}</td>;
              })}
            </tr>
          );
        })}
      </table>
    </>
  );
};
export default GridLogic;
