import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PaginationProps } from "antd";

export const PaginationText = (total: number, range: [number, number]) => {
  return (
    <>
      Showing{" "}
      <span className="fw-medium text-secondary mx-2">
        {range[0]} - {range[1]}
      </span>{" "}
      of <span className="fw-medium text-secondary ms-2">{total}</span>
    </>
  );
};

export const CustomPagination = (
  page: number,
  type: "next" | "prev" | "page" | "jump-prev" | "jump-next"
) => {
  if (type === "next") {
    return <RightOutlined className="pagination-icon" />;
  } else if (type === "prev") {
    return <LeftOutlined className="pagination-icon" />;
  }
  return null;
};

export const CustomPaginationWithPages: PaginationProps["itemRender"] = (
  page: number,
  type: "next" | "prev" | "page" | "jump-prev" | "jump-next",
  originalElement
) => {
  if (type === "next") {
    return <RightOutlined className="pagination-icon" />;
  } else if (type === "prev") {
    return <LeftOutlined className="pagination-icon" />;
  } else if (type === "page" || type === "jump-next" || type === "jump-prev") {
    return originalElement;
  }
  return null;
};
