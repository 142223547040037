import React from "react";

type Props = {
  headerText?: string;
  paragraphText?: string;
};

export const AuthLayout: React.FC<Props> = (props) => {
  return (
    <div className="position-relative auth-container">
      {/* <div className="left-top-image  d-none d-lg-block"></div>
      <div className="right-top-image d-none d-lg-block"></div>
      <div className="position-relative auth-text-holder justify-content-center">
        <div className="mw-400">
          <h1 className="text-white font-l fw-bold">{props.headerText}</h1>
          <p className="text-white">{props.paragraphText}</p>
        </div>
      </div>
      <div className="left-bottom-image  d-none d-lg-block"></div>
      <div className="right-bottom-image  d-none d-lg-block"></div> */}
      <div className="auth-block-container">
        <div
          className="auth-block btrr"
          // style={{
          //   backgroundImage: "url(/block-1.jpg)",
          // }}
        >
          <img
            src="/block-2.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="auth-block bblr"
          style={{ backgroundColor: "#FBBB9D" }}
        ></div>
        <div
          className="auth-block btlr"
          style={{
            backgroundColor: "#D2C7AD",
          }}
        >
          {/* <img
            src="/block-2.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          /> */}
        </div>
      </div>
      <div
        className="auth-text-container"
        style={{ backgroundColor: "#DBF6FF" }}
      >
        <div
          style={{
            color: "#004F6B",
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: 500,
          }}
          className="mb-3"
        >
          Welcome to the <strong>Bi</strong>specific <strong>T</strong>-Cell{" "}
          <strong>E</strong>ngager (BiTE) <strong>READI</strong>ness (BE READI)
          Survey
        </div>
        <div
          style={{
            color: "#004F6B",
            textAlign: "justify",
          }}
          className="font-m fw-medium mb-5"
        >
          New therapeutic interventions for managing Small Cell Lung Cancer
          (SCLC), like BiTE therapy, behold a promise to shift the paradigm of
          treatment options and survival from SCLC. As part of a quality
          improvement initiative, GO2 for Lung Cancer aims to engage healthcare
          providers (doctors, nurses, pharmacists, and other allied healthcare
          professionals) in completing this BE READI survey to help identify
          areas where we can support the lung cancer provider community when
          considering this treatment option for people living with SCLC.
        </div>
        <div
          style={{
            color: "#004F6B",
            textAlign: "justify",
          }}
          className="font-m fw-medium"
        >
          We hope you will consider taking this 10-minute survey to launch this
          work.
        </div>
      </div>
      <div className="auth-block-container">
        <div
          className="auth-block bblr bbrr"
          style={{ backgroundColor: "#B2BEDC" }}
        ></div>
        <div
          className="auth-block bblr"
          style={{
            backgroundColor: "#D7BCDC",
          }}
        >
          {/* <img
            src="/block-3.png"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          /> */}
        </div>
        <div
          className="auth-block btrr"
          style={{ backgroundColor: "#D3B4AC" }}
        ></div>
      </div>
      <div className="auth-block-container">
        <div
          className="auth-block btrr"
          style={{
            backgroundColor: "#CAA59B",
          }}
        >
          {/* <img
            src="/block-4.webp"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          /> */}
        </div>
        <div
          className="auth-block circle"
          style={{ backgroundColor: "#E6E6E6" }}
        ></div>
        <div
          className="auth-block btlr bbrr"
          // style={{
          //   backgroundImage: "url(/block-5.jpg)",
          // }}
        >
          <img
            src="/block-1.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
