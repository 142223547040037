export declare type ROLE = "Therapist" | "Admin";

const roles = {
  SystemAdmin: {
    be_value: "system_admin",
    fe_value: "Admin",
  },
  ResearchCoordinator: {
    be_value: "research_coordinator",
    fe_value: "Research Coordinator",
  },
  Researcher: {
    be_value: "researcher",
    fe_value: "Researcher",
  },
  Monitor: {
    be_value: "monitor",
    fe_value: "Monitor",
  },
  Observer: {
    be_value: "observer",
    fe_value: "Observer",
  },
};

export const getBERoleName = (type: string) => {
  let role = "";
  Object.entries(roles).forEach(([_1, val]: any) => {
    if (type === val.fe_value) {
      role = val.be_value;
    }
  });
  return role;
};

export default roles;
