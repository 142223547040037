import { connect } from "react-redux";
import { Input, Upload, Select, Tooltip, Button } from "antd";
import {
  textMap,
  choices,
  fieldMap,
  IconMap,
  QuestionLabelMap,
} from "./questionTypes";
import { toastMessage } from "../../../helpers/toastMessage";
import { UploadImage } from "../../../helpers/imageUpload";
import { v4 as uuid } from "uuid";
import {
  CogIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { CloudUploadIcon, DuplicateIcon } from "@heroicons/react/outline";

const groupOptions = choices.filter((choice: any) => choice.type !== "group");

type Props = {
  choice: any;
  index: number;
  accepted: any;
  handleDrop: Function;
  duplicate: Function;
  duplicationGroupQ: Function;
  delete: Function;
  setInnerFields: Function;
  chooseField?: boolean;
  UploadImage: Function;
  moveItem: Function;
  handleSequenceDrop: Function;
  orginalGroupQs?: any;
  originalGroupIndex?: any;
  questionnaireStatus: any;
  handleSettings: Function;
  showChinese: boolean;
};

const GroupChoiceField = (props: Props) => {
  const { choice, index } = props;
  const Icon = IconMap[choice.type];

  const addField = (idx?: number) => {
    const type = choice.fields[0].type;
    const toBeAdded = { type: type, id: uuid(), label: "", code: "" };

    const newField = {
      ...choice,
      fields: [...choice.fields, toBeAdded],
    };

    props.setInnerFields(index, newField);
  };

  const removeField = (idx: number) => {
    const otherField = choice.fields?.find?.((choice: any) => {
      return choice.isOther;
    });
    if ((!otherField && choice.fields.length > 1) || choice.fields.length > 2) {
      const newField = {
        ...choice,
        fields: choice.fields.filter((_: any, i: number) => i !== idx),
      };
      props.setInnerFields(index, newField);
    }
  };

  const handleChange = (idx: number, e: any, outer?: boolean) => {
    if (!outer) {
      const newVal = {
        ...choice,
        fields: choice.fields.map((f: any, i: number) =>
          i === idx ? { ...f, [e.target.name]: e.target.value } : f
        ),
      };
      props.setInnerFields(index, newVal);
    } else {
      const newVal = {
        ...choice,
        [e.target.name]: e.target.value,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const changeQuestionType = (type: string) => {
    const newField = {
      ...fieldMap(type),
      type,
      id: choice.id,
    };
    props.setInnerFields(index, newField);
  };

  const renderFields = (field: any, idx: number) => {
    switch (field.type) {
      case "dropdown":
        if (field.isOther) {
          return null;
        }
        return (
          <div
            className="field-group"
            key={idx}
            style={{ marginTop: idx === 0 ? "40px" : "0px" }}
          >
            <div className="checkboxes">
              <div className="d-flex flex-column" style={{ width: "50%" }}>
                <Input
                  name="label"
                  value={field.label}
                  className="singer-input"
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
                {props.showChinese && (
                  <Input
                    name="label_ch"
                    value={field.label_ch}
                    className="singer-input mt-4"
                    onChange={(e) => handleChange(idx, e)}
                    placeholder={`${textMap[choice.type].text} ${
                      idx + 1
                    } (Chinese)`}
                  />
                )}
              </div>
              <PlusIcon
                className="large-hero-icon text-gray cp"
                style={{ marginLeft: "30px" }}
                onClick={() => addField(idx)}
              />
              <TrashIcon
                className="large-hero-icon text-red cp"
                style={{ marginLeft: "30px" }}
                onClick={() => removeField(idx)}
              />
            </div>
          </div>
        );
      case "yes_no":
        return (
          <div
            className="field-group"
            key={idx}
            style={{ marginTop: idx === 0 ? "40px" : "0px" }}
          >
            <div className="checkboxes">
              <div className="d-flex flex-column" style={{ width: "50%" }}>
                <Input
                  name="label"
                  value={field.label}
                  className="singer-input"
                  disabled={true}
                />
                {props.showChinese && (
                  <Input
                    name="label_ch"
                    value={field.label_ch}
                    className="singer-input mt-4"
                    onChange={(e) => handleChange(idx, e)}
                    placeholder={`${idx === 0 ? "Yes" : "No"}  (Chinese)`}
                  />
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSettings = () => {
    props.handleSettings(props.index);
  };

  const handleImage = (fileObj: any) => {
    const handleUpload = (data: any) => {
      const newVal = {
        ...choice,
        attachment: {
          href: data[0].postUploadImageUrl,
          type: "image",
        },
      };
      props.setInnerFields(index, newVal);
    };
    props.UploadImage(fileObj, handleUpload, "questionnaire_image");
  };

  const clearImage = (event: any) => {
    event.stopPropagation();
    const newVal = {
      ...choice,
      attachment: {},
    };
    props.setInnerFields(index, newVal);
  };

  return (
    <div
      className={
        props.questionnaireStatus === "draft"
          ? "question-choice-field"
          : "question-choice-field disabled-div-qb"
      }
    >
      {props.chooseField && (
        <div className="question-group-choice">
          <span className="text-prime font-m fw-semibold">Question Type</span>
          <Select
            onChange={changeQuestionType}
            value={choice.type}
            size="large"
            className="question-group-choice-selector"
          >
            {groupOptions.map((option: any) => (
              <Select.Option key={option.key} value={option.type}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}

      <div className="d-flex cp">
        <Tooltip placement="left" title={QuestionLabelMap[choice.type]}>
          <div className="question-type">
            <span className="question-index">{index + 1}</span>
            {Icon && <Icon />}
          </div>
        </Tooltip>
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={handleImage}
          accept=".gif,.jpg,.jpeg,.png"
        >
          <Button
            style={{ height: "40px", width: "100px", borderRadius: "12px" }}
            className="me-4 question-picture"
            title="Image upload"
          >
            {choice.attachment?.href ? (
              <img
                src={choice.attachment?.href}
                alt="question"
                className="question-thumbnail"
              />
            ) : (
              <CloudUploadIcon className="large-hero-icon cp" />
            )}
            {choice.attachment?.href && (
              <XCircleIcon className="picture-clear" onClick={clearImage} />
            )}
          </Button>
        </Upload>
        <span style={{ flex: 1, minWidth: "0px" }}>
          <Input
            value={choice.title}
            name="title"
            className="singer-input"
            onChange={(e) => handleChange(index, e, true)}
            placeholder="Type here"
          />
          {props.showChinese && (
            <Input
              value={choice.title_ch}
              name="title_ch"
              className="singer-input mt-4"
              onChange={(e) => handleChange(index, e, true)}
              placeholder="Type here (Chinese)"
            />
          )}
        </span>
      </div>

      {choice.fields &&
        choice.fields.map((field: any, idx: number) =>
          renderFields(field, idx)
        )}

      <div className="questionnaire-actions">
        <div className="response me-3">
          <Tooltip placement="bottom" title="Configuration">
            <CogIcon
              className="large-hero-icon cp"
              style={{ color: "#71717A" }}
              onClick={handleSettings}
            />
          </Tooltip>
        </div>
        <div className="duplicate me-3">
          <Tooltip placement="bottom" title="Duplicate">
            <DuplicateIcon
              className="large-hero-icon text-gray cp"
              onClick={() => {
                if (props.orginalGroupQs && props.duplicationGroupQ) {
                  props.duplicationGroupQ(props.orginalGroupQs, choice);
                } else {
                  props.duplicate(index, choice.type);
                }
              }}
            />
          </Tooltip>
        </div>
        <div className="delete">
          <Tooltip placement="bottom" title="Delete">
            <TrashIcon
              className="large-hero-icon text-red cp"
              onClick={() => props.delete(index)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { UploadImage })(GroupChoiceField);
