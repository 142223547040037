import React from "react";
import { Select, Input, DatePicker, Tooltip, Button } from "antd";
import { conditions, textMap } from "./questionTypes";
import moment from "moment";
import { TrashIcon } from "@heroicons/react/solid";

type Props = {
  question: any;
  preview?: boolean;
  questions: any[];
  modifyQuestion: any;
};

const initState: any = {
  TEXT: {
    condition: "contains",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  CHOICE: {
    condition: "is",
    variable: {
      type: "choice",
      value: "",
    },
    nextQuestionId: "",
  },
  BOOL: {
    condition: "is",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  NUMBER: {
    condition: "lt",
    variable: {
      type: "number",
      value: 0,
    },
    nextQuestionId: "",
  },
  DATE: {
    condition: "on",
    variable: {
      type: "date",
      value: "",
    },
    nextQuestionId: "",
  },
};

const BranchLogic: React.FC<Props> = ({
  question,
  modifyQuestion,
  preview,
  questions,
}) => {
  const addLogicBranch = () => {
    const newQuestion = {
      ...question,
      branches: [...question.branches, initState[question.branchType]],
    };
    modifyQuestion(newQuestion);
  };

  const removeLogicBranch = (index: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.filter((_: any, i: number) => i !== index),
    };
    modifyQuestion(newQuestion);
  };

  const handleValue = (val: any, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx
          ? {
              ...branch,
              variable: {
                ...branch.variable,
                value: val,
              },
            }
          : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const handleNumberValue = (val: any, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx
          ? {
              ...branch,
              variable: {
                ...branch.variable,
                value: val ? Number(val) : "",
              },
            }
          : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const handleChange = (name: string, val: string, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx ? { ...branch, [name]: val } : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const renderConditionOptions = (type: string) =>
    conditions[type].map((cond: any) => (
      <Select.Option key={cond.label} value={cond.value} className="form-input">
        {cond.label}
      </Select.Option>
    ));

  const renderAnswer = (branch: any, idx: number) => {
    switch (question.branchType) {
      case "TEXT":
        return (
          <Input
            className="singer-input"
            value={branch.variable.value}
            style={{ width: "100%" }}
            placeholder="Type text here"
            readOnly={preview}
            onChange={(e: any) => handleValue(e.target.value, idx)}
          />
        );
      case "NUMBER":
        return (
          <Input
            type="number"
            placeholder="type numeric values here"
            value={branch.variable.value}
            style={{ width: "100%" }}
            className="singer-input"
            readOnly={preview}
            onChange={(e: any) => handleNumberValue(e.target.value, idx)}
          />
        );
      case "DATE":
        return (
          <DatePicker
            inputReadOnly
            value={
              branch.variable.value !== ""
                ? moment(branch.variable.value)
                : null
            }
            format="YYYY-MM-DD"
            className="logic-jump-datepicker"
            style={{ width: "100%" }}
            onChange={(date: any) => {
              const dateString = date.format("YYYY-MM-DD");
              handleValue(dateString, idx);
            }}
          />
        );
      case "BOOL":
        return (
          <Select
            style={{ width: "100%" }}
            value={branch.variable.value}
            onChange={(val) => handleValue(val, idx)}
            className="response-choice-selector"
            size="large"
          >
            <Select.Option value="" className="d-none">
              Select Yes/No
            </Select.Option>
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        );
      case "CHOICE":
        return (
          <Select
            style={{ width: "100%" }}
            className="response-choice-selector"
            size="large"
            value={branch.variable.value}
            onChange={(val) => handleValue(val, idx)}
          >
            <Select.Option value="" className="d-none">
              {`Select ${textMap[question.type].text}`}
            </Select.Option>
            {question.fields.map((field: any) => (
              <Select.Option
                key={field.id}
                value={field.id}
                style={{
                  pointerEvents:
                    field.label === "" && !field.isOther ? "none" : "auto",
                }}
              >
                {field.label !== ""
                  ? field.label
                  : field.isOther
                  ? "Other"
                  : "No Values"}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  const questionTitle = (question: any, idx: number, grpIdx?: number) => {
    return grpIdx ? (
      <Tooltip
        color="orange"
        placement="rightTop"
        title={`${grpIdx}.${idx + 1}. ${question.title}`}
      >
        <p>{`${grpIdx}.${idx + 1}. ${question.title}`}</p>
      </Tooltip>
    ) : (
      <Tooltip
        color="orange"
        placement="rightTop"
        title={`${idx + 1}. ${question.title}`}
      >
        <p>{`${idx + 1}. ${question.title}`}</p>
      </Tooltip>
    );
  };

  const renderQuestions = (questions: any) =>
    questions.map((que: any, index: number) => {
      if (que.id !== question.id) {
        if (que.type !== "group") {
          return (
            <Select.Option key={que.id} value={que.id}>
              {questionTitle(que, index)}
            </Select.Option>
          );
        } else {
          return que.questions.map((nestedQuestion: any, nestedIdx: number) => {
            if (nestedQuestion.id !== question.id) {
              return (
                <Select.Option
                  key={nestedQuestion.id}
                  value={nestedQuestion.id}
                >
                  {questionTitle(nestedQuestion, nestedIdx, index + 1)}
                </Select.Option>
              );
            } else {
              return null;
            }
          });
        }
      } else {
        return null;
      }
    });

  const renderBranch = (branch: any, idx: number) => {
    return (
      <div
        key={idx}
        className={
          preview
            ? "branch-container disabled-div-qb mb-3"
            : "branch-container mb-5"
        }
      >
        <div className="row m-0">
          <div className="col-6">
            <div className="d-flex flex-column">
              <span className="form-label">If condition</span>
              <Select
                style={{ width: "100%" }}
                className="response-choice-selector"
                size="large"
                value={branch.condition}
                onChange={(val: string) => handleChange("condition", val, idx)}
              >
                {renderConditionOptions(question.branchType)}
              </Select>
            </div>
          </div>
          <div className="col-6 p-0 pl-2">
            <div className="d-flex flex-column">
              <span className="form-label">the value</span>
              {renderAnswer(branch, idx)}
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="text-secondary fw-medium me-3">Then Jump to</span>
            <Select
              style={{ width: "250px" }}
              value={
                questions.find((q: any) =>
                  q.type === "group"
                    ? q.questions.find(
                        (nQ: any) => nQ.id === branch.nextQuestionId
                      )
                    : q.id === branch.nextQuestionId
                )
                  ? branch.nextQuestionId
                  : ""
              }
              className="response-choice-selector"
              size="large"
              onChange={(val: string) =>
                handleChange("nextQuestionId", val, idx)
              }
            >
              <Select.Option value="" className="d-none">
                Select Question
              </Select.Option>
              {renderQuestions(questions)}
            </Select>
          </div>
          <TrashIcon
            className="large-hero-icon text-red cp ms-3"
            onClick={() => removeLogicBranch(idx)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4 mb-1">
      {question.branches.length > 0 ? (
        <div>
          <div
            className={
              preview
                ? "d-none"
                : "d-flex justify-content-between align-items-center mb-5"
            }
          >
            <span className="pl-2 font-m text-secondary fw-medium">
              When someone answers Question {question.position}
            </span>
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={addLogicBranch}
            >
              <span className="fw-semibold font-sm">+ Add branch</span>
            </Button>
          </div>
          {question.branches.map((branch: any, idx: number) => {
            if (branch.condition !== "always") {
              return renderBranch(branch, idx);
            } else {
              return null;
            }
          })}
        </div>
      ) : (
        <div>
          <h3 className="font-m text-secondary fw-medium mb-3">
            Show different questions based on people's answers
          </h3>
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={addLogicBranch}
          >
            <span className="fw-semibold font-sm">Add Logic Jump</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default BranchLogic;
