import { Route, Routes } from "react-router-dom";
import ScoreList from "../Scores/Scores";
import QuestionBuilder from "./QuestionBuilder/QuestionBuilder";
import QuestionPreview from "./QuestionPreview/QuestionPreview";
import CreateScore from "../Scores/CreateScore";
import QuestionList from "./QuestionList/QuestionList";
import { AuthenticatedRoute } from "../Common/RouteComponents";
import { AmendQuestions } from "../../helpers/roles";

const QuestionRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <QuestionList />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/builder"
        element={
          <AuthenticatedRoute allowedRoles={AmendQuestions}>
            <QuestionBuilder />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/preview"
        element={
          <AuthenticatedRoute>
            <QuestionPreview />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/score"
        element={
          <AuthenticatedRoute>
            <ScoreList />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/score/create"
        element={
          <AuthenticatedRoute allowedRoles={AmendQuestions}>
            <CreateScore />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};

export default QuestionRoutes;
