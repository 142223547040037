import React, { useState, useEffect, useMemo } from "react";
import { Button, ConfigProvider, Empty, Input } from "antd";
import { setModalDetails } from "../../redux/reducers/modalSlice";

import { Table, Menu, Dropdown } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChevronLeftIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { canAmendQuestions } from "../../helpers/roles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ScoreList: React.FC = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const qid: string = query.get("id") || "";
  const name = query.get("name") || "";

  const navigate = useNavigate();
  const role = useAppSelector((state) => state.user.role);
  const [adminAccess] = useState(canAmendQuestions(role));
  const [page, setPage] = useState(1);
  const [scores, setScores] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setAppLoader(true));
        let url = `/score_expressions?page=${page}&size=8&questionnaireId=${qid}`;
        if (search) {
          url += `&search=${search}`;
        }
        const res = await http.get(url);
        const scores = res.data.data.scoreExpressions.map((item: any) => {
          return {
            id: item.id,
            name: item.title,
            date: moment(item.createdAt).format("DD/MM/YYYY"),
            status: item.status,
          };
        });
        setScores(scores);
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setTotal, setScores, dispatch, qid, page, search, loader]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const columns = useMemo(() => {
    const deleteScore = async (sid: any) => {
      try {
        dispatch(setAppLoader(true));
        await http.delete(`/score_expressions/${sid}`);
        setLoader((prev) => !prev);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };

    const menu = (data: any) => (
      <Menu>
        <Menu.Item key="0" onClick={() => deleteScore(data.id)}>
          Delete
        </Menu.Item>
      </Menu>
    );
    const actionIcon = (data: any) => {
      return (
        <span onClick={(e) => e.stopPropagation()}>
          <Dropdown
            overlay={menu(data)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <DotsVerticalIcon
              className="hero-icon cp"
              style={{ color: "#71717A", marginLeft: "auto" }}
            />
          </Dropdown>
        </span>
      );
    };
    const temp: any[] = [
      {
        title: "Score name",
        dataIndex: "name",
        width: "25%",
        render: (text: string) => {
          return <div className="text-primary">{text}</div>;
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        width: "20%",
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "20%",
        render: (text: string) => (
          <span
            className={
              text === "active" ? "active-tag badge" : "inactive-tag badge"
            }
          >
            {text}
          </span>
        ),
      },
    ];
    if (adminAccess) {
      temp.push({
        title: "",
        render: (_: any, data: any) => actionIcon(data),
      });
    }
    return temp;
  }, [adminAccess, dispatch]);

  const onSelect = (record: any) => {
    if (record.status === "active" || !adminAccess) {
      dispatch(
        setModalDetails({
          type: "SCORE_PREVIEW",
          modalProps: {
            show: true,
            title: "Score Preview",
            qid: qid,
            qname: name,
            scoreId: record.id,
          },
        })
      );
    } else if (record.status === "draft") {
      navigate(
        `/question/score/create?id=${qid}&name=${name}&scoreId=${record.id}`
      );
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const createScore = () => {
    navigate(`/question/score/create?id=${qid}&name=${name}`);
  };

  const handleSearch = (event: any) => {
    setSearch(event?.target.value);
  };

  return (
    <div className="scores">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-3 text-secondary cp"
          onClick={handleBack}
        />
        <div className="font-m fw-semibold font-l text-secondary">{name}</div>
        <Input
          placeholder="Search..."
          onPressEnter={handleSearch}
          style={{ width: "200px", marginLeft: "40px" }}
          className="singer-input"
        />
        {adminAccess && (
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px", marginLeft: "auto" }}
            className="me-4"
            onClick={createScore}
          >
            <span className="fw-semibold font-sm">Create Score</span>
          </Button>
        )}
      </div>
      <div className="table-scores-container">
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No response found"
            />
          )}
        >
          <Table
            columns={columns}
            dataSource={scores}
            rowClassName={(record) =>
              record.status === <span>Active</span> ? "active" : "inactive"
            }
            onRow={(record: any) => ({ onClick: () => onSelect(record) })}
            className="cp singer-custom-table"
            pagination={{
              current: page,
              total: total,
              showSizeChanger: false,
              pageSize: 8,
              hideOnSinglePage: true,
              showTotal: PaginationText,
              className: "singer-table-pagination",
              position: ["bottomLeft"],
              itemRender: CustomPagination,
              onChange: handlePagination,
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ScoreList;
