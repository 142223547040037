import { DatePicker, Input, TimePicker, Upload } from "antd";
import { UploadImageIcon } from "../../../Common/Icons";

const { Dragger } = Upload;

export const InputType: React.FC<any> = ({ question }) => {
  const { properties, uploadable } = question;
  const customRequest = () => {};
  return (
    <>
      <div className="preview-text-field">
        <Input
          type="number"
          className="preview-number"
          placeholder="Enter value here"
          max={properties.maxRange}
          min={properties.minRange}
        />
      </div>
      {uploadable && (
        <Dragger
          className="preview-file"
          multiple={false}
          accept="image/*"
          showUploadList={false}
          customRequest={customRequest}
        >
          <div className="d-flex align-items-center justify-content-center">
            <UploadImageIcon />
            <span className="ms-3 fw-semibold font-sm color-primary">
              {`Drop files to upload (max file size - ${question.properties.maxRange}MB)`}
            </span>
          </div>
        </Dragger>
      )}
    </>
  );
};

export const TextType: React.FC<any> = ({ question }) => {
  const { properties, type } = question;

  return (
    <div className="preview-text-field">
      <Input.TextArea
        placeholder="Type your text here"
        rows={type === "short_text" ? 2 : 3}
        showCount
        maxLength={properties.maxRange}
      />
    </div>
  );
};

export const DateType: React.FC<any> = ({ question }) => {
  return (
    <div className="preview-text-field">
      <DatePicker
        showTime={question.type === "date_time"}
        className="date-selector"
        format={
          question.type === "date_time" ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"
        }
      />
    </div>
  );
};

export const TimeType: React.FC<any> = ({ question }) => {
  return (
    <div className="preview-text-field">
      <TimePicker className="date-selector" format="HH:mm" />
    </div>
  );
};

export const FileType: React.FC<any> = ({ question }) => {
  const customRequest = () => {};
  return (
    <>
      <Dragger
        className="preview-file"
        multiple={false}
        accept="image/*"
        showUploadList={false}
        customRequest={customRequest}
      >
        <div className="d-flex align-items-center justify-content-center">
          <UploadImageIcon />
          <span className="ms-3 fw-semibold font-sm color-primary">
            {`Drop files to upload (max file size - ${question.properties.maxRange}MB)`}
          </span>
        </div>
      </Dragger>
    </>
  );
};
