import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
  studyProgress: any;
  inclusions: any[];
  learningAnalytics: any[];
  ecrfProgress: any[];
  eproProgress: any[];
  dashboardType: number | null;
}

const initialState: DashboardState = {
  dashboardType: null,
  studyProgress: {
    enrolled: 0,
    enrolledPercent: 0,
    phases: [],
    inclusions: 0,
    exclusions: 0,
    queryTotal: 0,
    queryOpen: 0,
  },
  inclusions: [],
  learningAnalytics: [
    { id: 1, name: "Education Modules", total: 0, color: "#E74694" },
    {
      id: 2,
      name: "Active Education Modules",
      total: 0,
      color: "#0E9F6E",
      percent: 0,
    },
    {
      id: 3,
      name: "Quizzes & Assessment",
      total: 0,
      color: "#E74694",
    },
    {
      id: 4,
      name: "Active Quizzes & Assessment",
      total: 0,
      color: "#0E9F6E",
      percent: 0,
    },
  ],
  ecrfProgress: [],
  eproProgress: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setStudyProgress: (state, action: PayloadAction<{ data: any }>) => {
      state.studyProgress = action.payload.data;
    },
    setInclusions: (state, action: PayloadAction<{ data: any }>) => {
      state.inclusions = action.payload.data;
    },
    setLearningAnalytics: (state, action: PayloadAction<{ data: any }>) => {
      state.learningAnalytics = action.payload.data;
    },
    setCrfProgress: (state, action: PayloadAction<{ data: any }>) => {
      state.ecrfProgress = action.payload.data;
    },
    setEproProgress: (state, action: PayloadAction<{ data: any }>) => {
      state.eproProgress = action.payload.data;
    },
    setDashboardType: (state, action: PayloadAction<{ data: number }>) => {
      state.dashboardType = action.payload.data;
    },
  },
});

export const {
  setStudyProgress,
  setInclusions,
  setLearningAnalytics,
  setCrfProgress,
  setEproProgress,
  setDashboardType,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
