import { useState, useEffect, useCallback } from "react";
import { Modal } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import {
  scoresFromResponse,
  scoreEvalFromResponse,
} from "../../../helpers/score";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage } from "../../../helpers/toastMessage";

type Props = {
  qid?: any;
  qname?: string;
  show: boolean;
  title: string;
  scoreId: any;
};

const calcButtons = [
  {
    func: "AC",
    classValue: "#A0A5B8",
    label: "AC",
  },
  {
    func: "constant",
    classValue: "#A0A5B8",
    label: "CONST",
  },
  {
    type: "paranthesis",
    func: "(",
    classValue: "#A0A5B8",
    label: "(",
  },
  {
    type: "paranthesis",
    func: ")",
    classValue: "#A0A5B8",
    label: ")",
  },
  {
    func: "%",
    classValue: "#A0A5B8",
    label: "%",
  },
  {
    func: "*",
    classValue: "#00B9FC",
    iconValue: "score-multiply-icon",
    multiLabel: "x",
  },
  {
    func: "-",
    classValue: "#00B9FC",
    iconValue: "score-subtract-icon",
  },
  {
    func: "+",
    classValue: "#00B9FC",
    iconValue: "score-add-icon",
  },
  {
    func: "/",
    classValue: "#00B9FC",
    iconValue: "score-divide-icon",
  },
];

const scoreConditionTypes: any = {
  gt: "Greater than",
  gte: "Greater than or equal to",
  lt: "Less than",
  lte: "Less than or equal to",
  between: "Between",
  eq: "Equal to",
};

const ScorePreview = (props: Props) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  let [scores, setScores] = useState<any[]>([]);
  let [scoreEval, setEval] = useState<any[]>([]);
  const [questions, setQuestions] = useState<any[]>([]);
  const { qid, scoreId } = props;

  const updateStateInfo = useCallback(
    async (scoreId) => {
      const response = await http.get(`/score_expressions/${scoreId}`);
      let scoreInfoValues = await response.data.data;
      setScores(scoresFromResponse(scoreInfoValues.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(scoreInfoValues.scoreExpressionConditions));
      setName(scoreInfoValues.title);
    },
    [setScores, setEval, setName]
  );

  useEffect(() => {
    if (scoreId !== undefined) {
      updateStateInfo(scoreId);
    }
  }, [scoreId, updateStateInfo]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (qid) {
        try {
          dispatch(setAppLoader(true));
          let { data } = await http.get(`/questionnaires/${qid}/questions`);
          let ques: any[] = [];
          const temp = data.data.questions;
          temp.sort(function (a: any, b: any) {
            return a.position - b.position;
          });
          temp.forEach((que: any) => {
            if (que.type !== "group") {
              ques.push({
                questionId: que.id,
                questionTitle: que.title,
                questionNo: `Q${que.position}`,
              });
            } else {
              const groupQues = que?.properties?.questions || [];
              groupQues.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              groupQues.forEach((q: any) => {
                ques.push({
                  questionId: q.id,
                  questionTitle: q.title,
                  questionNo: `Q${que.position}.${q.position}`,
                });
              });
            }
          });
          setQuestions(ques);
          dispatch(setAppLoader(false));
        } catch (err) {
          dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
        }
      }
    };
    fetchDetails();
  }, [qid, setQuestions, dispatch]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={`${name} Preview`}
      visible={props.show}
      onCancel={closeHandler}
      width={"70rem"}
      footer={null}
    >
      <div className="score-preview">
        <h5 className="font-ml fw-semibold">Questions</h5>
        <div className="score-preview-body row">
          <div className="score-preview-questions col-md-5 ps-0">
            {questions.length > 0 &&
              questions.map((item: any, index: any) => {
                return (
                  <div className="score-preview-q row cp" key={index}>
                    <div className="question-number col-md-2">
                      {item.questionNo}
                    </div>
                    <div className="question-title col-md-10">
                      {item.questionTitle}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="score-preview-data col-md-7 ps-4">
            <div className="score-preview-exp">
              <h5 className="mb-3 fw-semibold">Expression</h5>
              <div className="score-box">
                <span className="score-x">X =</span>
                {scores.length > 0 &&
                  scores.map((item: any, _: any) => {
                    return (
                      <input
                        className={
                          item.type === "constant"
                            ? "const-input"
                            : item.type === "question"
                            ? "question-input"
                            : "operator-input"
                        }
                        type="text"
                        value={
                          item.type === "question"
                            ? questions.find(
                                (ques: any) => ques.questionId === item.value
                              )?.questionNo
                            : item.value !== "*"
                            ? item.value
                            : calcButtons[5].multiLabel
                        }
                        readOnly
                      />
                    );
                  })}
              </div>
            </div>
            <div className="score-preview-conditions">
              <h5>Scores</h5>
              {scoreEval.map((_: any, index: any) => {
                const conditionType =
                  scoreConditionTypes[scoreEval[index].condition];
                return (
                  <div className="score-conditions" key={`${index}-score-eval`}>
                    <div className="d-flex align-items-center">
                      <span className="me-4">If X</span>
                      <div className="me-4 score-border-cond">
                        {conditionType}
                      </div>
                      <span className="me-4 score-border-cond">
                        {conditionType === "Between"
                          ? `${scoreEval[index].minValue} and ${scoreEval[index].maxValue}`
                          : conditionType === "Less than" ||
                            conditionType === "Less than or equal to"
                          ? `${scoreEval[index].maxValue}`
                          : `${scoreEval[index].minValue}`}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginTop: "3rem" }}
                    >
                      <span className="me-4">{`Then Score`}</span>
                      <div className="score-border-cond">{`${scoreEval[index].score}`}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScorePreview;
