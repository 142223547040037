import { Dispatch } from "redux";
import { toastMessage, errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import * as types from "../actionTypes";
import { setAppLoader } from "../reducers/loaderSlice";

export const setQuestionnaireScroll =
  (questions: any, count: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.INFINITE_GET_QUESTIONS,
      payload: {
        questions: questions,
        count: count,
      },
    });
  };

export const getQuestionList =
  (
    pageNo: number,
    status?: string,
    scroll?: string | boolean,
    pageSize?: number
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setAppLoader(true));
    pageSize = pageSize !== undefined ? pageSize : 12;
    const url = `/questionnaires?page=${pageNo}&size=${pageSize}`;
    const extraParam = status === undefined ? url : `${url}&status=${status}`;

    http
      .get(extraParam)
      .then((res) => {
        if (scroll) {
          dispatch({
            type: types.INFINITE_GET_QUESTIONS,
            payload: {
              questions: res.data.data.questionnaires,
              count: res.data.data.count,
            },
          });
        } else {
          dispatch({
            type: types.GET_QUESTIONS,
            payload: {
              questions: res.data.data.questionnaires,
              count: res.data.data.count,
            },
          });
        }
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const getProjectQuestionsList =
  (pageNo?: number, projectId?: any, index?: any) =>
  (dispatch: Dispatch, getState: any) => {
    const pageSize = 12;
    dispatch(setAppLoader(true));
    http
      .get(
        `/questionnaires?page=${pageNo}&size=${pageSize}&status=active&projectId=${projectId}`
      )
      .then((res) => {
        let projectQues = getState().question.questionsProjects;
        let found = projectQues.some((item: any) => item.idx === index);

        let finalPayload = found
          ? projectQues.map((item: any) => {
              return item.idx === index
                ? { idx: index, ques: res.data.data.questionnaires }
                : item;
            })
          : [
              ...projectQues,
              { idx: index, ques: res.data.data.questionnaires },
            ];

        dispatch({
          type: types.GET_QUESTIONS_LIST_PROJECTS,
          payload: {
            questions: finalPayload,
            count: res.data.data.count,
          },
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const updateProjectsQuestions =
  (projQues: any) => (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: types.GET_QUESTIONS_LIST_PROJECTS,
      payload: {
        questions: projQues,
        count: getState().question.qpCount,
      },
    });
  };

export const getTotalQuestionsList = () => (dispatch: Dispatch) => {
  dispatch(setAppLoader(true));
  http
    .get(`/questionnaires`)
    .then((res) => {
      dispatch({
        type: types.GET_QUESTIONS,
        payload: {
          questions: res.data.data.questionnaires,
          count: res.data.data.count,
        },
      });
      dispatch(setAppLoader(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAppLoader(false));
    });
};

export const searchQuestionModules =
  (searchItem: string, status?: string) => (dispatch: Dispatch) => {
    const pageNo = 1;
    const pageSize = 10;
    dispatch(setAppLoader(true));
    const url = `/questionnaires?page=${pageNo}&size=${pageSize}&search=${searchItem}`;
    const extraParam = status === undefined ? url : `${url}&status=${status}`;
    http
      .get(extraParam)
      .then((res) => {
        dispatch({
          type: types.GET_QUESTIONS,
          payload: {
            questions: res.data.data.questionnaires,
            count: res.data.data.count,
          },
        });
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };

export const deleteQuestionModule =
  (id: string, callback: Function) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.delete(`/questionnaires/${id}`);
      toastMessage("success", res.data.message);
      callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const getQuestionsFilter =
  (id: string, index?: any) => (dispatch: Dispatch, getState: any) => {
    dispatch(setAppLoader(true));
    http
      .get(`/questionnaires/${id}/questions`)
      .then((res) => {
        let qFilter = getState().question.questionsFilter;
        let found = qFilter.some((item: any) => item.idx === index);

        if (res.data.data.questions[0].type === "group") {
          if (
            res.data.data.questions[0].properties.questions[0].properties.hasOwnProperty(
              "responseCodes"
            )
          ) {
            let finalPayload = found
              ? qFilter.map((item: any) => {
                  return item.idx === index
                    ? { idx: index, ...res.data.data.questions[0].properties }
                    : item;
                })
              : [
                  ...qFilter,
                  { idx: index, ...res.data.data.questions[0].properties },
                ];

            dispatch({
              type: types.GET_QUESTIONS_FILTER,
              payload: finalPayload,
            });
          } else {
            toastMessage(
              "warning",
              "No response codes available for this question group"
            );
          }
        } else {
          let finalPayload = found
            ? qFilter.map((item: any) => {
                return item.idx === index
                  ? { idx: index, ...res.data.data }
                  : item;
              })
            : [...qFilter, { idx: index, ...res.data.data }];

          dispatch({
            type: types.GET_QUESTIONS_FILTER,
            payload: finalPayload,
          });
        }
        dispatch(setAppLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAppLoader(false));
      });
  };

export const resetQuestionsFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: types.RESET_QUESTIONS_FILTER,
    payload: [],
  });
};

export const updateQuestionsFilter =
  (questionsFilter: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.GET_QUESTIONS_FILTER,
      payload: questionsFilter,
    });
  };

export const questionnaireDuplicate =
  (id: string, callback: Function) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post(`/questionnaires/${id}/duplicate`);
      toastMessage("success", res.data.message);
      callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const setPreviewQuestions = (questions: any[] = [], logicJumps = []) => {
  return {
    type: types.SET_PREVIEW_QUESTIONS,
    payload: { questions, logicJumps },
  };
};

export const setQuestionnaire = (questions: any[] = [], count = 0) => ({
  type: "SET_QUESTIONNAIRE",
  payload: { questions, count },
});

export const activateDeactivateQuestionnaire =
  (questionId: any, body: any, callback: Function) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAppLoader(true));
      await http.put(`/questionnaires/${questionId}`, body);
      callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };
