import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DocumentsState {
  documents: any[];
  folderFiles: any[];
  docCount: number;
  archiveLists: any[];
  searchTerm: string;
  nestedSearchTerm: string;
  sortOrder: string;
}

const initialState: DocumentsState = {
  documents: [],
  folderFiles: [],
  docCount: 0,
  archiveLists: [],
  searchTerm: "",
  nestedSearchTerm: "",
  sortOrder: "createdAt-desc",
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocuments: (state, action: PayloadAction<any>) => {
      state.documents = action.payload.documents;
      state.docCount = action.payload.docCount;
    },
    setFolderDocuments: (state, action: PayloadAction<any>) => {
      state.folderFiles = action.payload;
    },
    setArchiveDocuments: (state, action: PayloadAction<any>) => {
      state.archiveLists = action.payload;
    },
    setDocumentSearch: (state, action: PayloadAction<any>) => {
      state.searchTerm = action.payload;
    },
    setNestedDocumentSearch: (state, action: PayloadAction<any>) => {
      state.nestedSearchTerm = action.payload;
    },
    setDocumentSorter: (state, action: PayloadAction<any>) => {
      state.sortOrder = action.payload;
    },
  },
});

export const {
  setDocuments,
  setFolderDocuments,
  setArchiveDocuments,
  setDocumentSorter,
  setDocumentSearch,
  setNestedDocumentSearch,
} = documentsSlice.actions;

export default documentsSlice.reducer;
