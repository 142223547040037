export const getStudyId = () => {
  return "ade58392-631c-4435-908d-d4a0ebbfea75";
  // return "B09DB2CA-36FF-4ADB-9B20-66FA9FFCFF13";
};

const phase: any = {
  screening: "c86ecfcd-6d4d-46fa-9ddd-a76e9464477f",
  baseline: "1e37a940-1d32-4e79-8ba8-2ebec0dde953",
  visit_1: "277d2e99-e607-4400-a6f9-4ca2ea6bb687",
  visit_2: "6e9c8637-223c-41b6-b976-eb4428d9fe84",
};

export const getPhaseId = (key: string) => {
  return phase[key];
};
