import { Progress } from "antd";

type Props = {
  completionProgress: {
    percent: number;
    total: number;
    completed: number;
  };
};
const SurveyProgress: React.FC<Props> = ({ completionProgress }) => {
  return (
    <div className="survey-progress">
      <div className="font-sm fw-bold mb-1">Progress</div>
      <Progress
        percent={completionProgress.percent}
        showInfo={false}
        strokeColor={"#31C48D"}
      />
      <div className="font-xs fw-medium color-primary">{`${completionProgress.completed}/${completionProgress.total} completed`}</div>
    </div>
  );
};

export default SurveyProgress;
