import * as types from "../actionTypes";

const initialState = {
  questions: [],
  questionsProjects: [],
  count: 0,
  qpCount: 0,
  logicJumps: [],
  previewQuestions: [],
  previewLogicJumps: [],
  questionsFilter: [],
};

const questionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        count: action.payload.count,
      };

    case types.GET_QUESTIONS_LIST_PROJECTS:
      return {
        ...state,
        questionsProjects: action.payload.questions,
        qpCount: action.payload.count,
      };

    case types.INFINITE_GET_QUESTIONS:
      return {
        ...state,
        questions: [...state.questions, ...action.payload.questions],
        count: action.payload.count,
      };
    case types.SEARCH_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case types.SET_PREVIEW_QUESTIONS:
      return {
        ...state,
        previewQuestions: action.payload.questions,
        previewLogicJumps: action.payload.logicJumps,
      };
    case types.GET_QUESTIONS_FILTER:
      return {
        ...state,
        questionsFilter: action.payload,
      };
    case types.RESET_QUESTIONS_FILTER:
      return {
        ...state,
        questionsFilter: action.payload,
      };

    default:
      return { ...state };
  }
};

export default questionReducer;
