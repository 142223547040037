import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  type: string;
  modalProps: any;
}

const initialState: UserState = {
  type: "",
  modalProps: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalDetails: (
      state,
      action: PayloadAction<{ type: string; modalProps: any }>
    ) => {
      state.type = action.payload.type;
      state.modalProps = action.payload.modalProps;
    },
    hideModal: (state) => {
      state.type = "";
      state.modalProps = null;
    },
  },
});

export const { setModalDetails, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
