import React, { useState } from "react";
import { Formik } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import {
  Modal,
  Upload,
  Input,
  Button,
  //  Select
} from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { getStudyId } from "../../../helpers/study";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().required("Module Name is Required"),
});

type Props = {
  show: boolean;
  modalType: string;
  questionData?: any;
  readType?: any;
  refreshPage: Function;
};

const AddQuestions = (props: Props) => {
  const dispatch = useAppDispatch();

  const [imgData, setImgData] = useState({
    expiresAt: "",
    fileName: "",
    postUploadImageUrl: "",
    presignedUploadUrl: "",
    type: "",
  });

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUpload = (data: any) => {
    setImgData(data[0]);
  };

  const handleAvater = (fileObj: any) => {
    dispatch(UploadImage(fileObj, handleUpload, "questionnaire_image"));
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      let data = {
        ...values,
        studyId: getStudyId(),
      };
      if (data.name.trim() === "") {
        throw new Error(`No white spaces are allowed in Questionnaire name`);
      }
      data.imageUrl =
        imgData.postUploadImageUrl !== ""
          ? imgData.postUploadImageUrl
          : data.imageUrl;
      if (data.imageUrl === "") {
        delete data.imageUrl;
      }
      if (props.questionData) {
        data["status"] = props.questionData.status;
        if (data.description === "") {
          data.description = ".";
        }
        const res = await http.put(
          `/questionnaires/${props.questionData.id}`,
          data
        );
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        props.refreshPage();
      } else {
        const res = await http.post("/questionnaires", data);
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        props.refreshPage();
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={props.questionData ? "Edit Questionnaire" : "Add Questionnaire"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            name: props.questionData?.name || "",
            name_ch: props.questionData?.name_ch || "",
            description: props.questionData?.description || "",
            imageUrl: props.questionData?.imageUrl || "",
            category: props.questionData?.category || 1,
            // isEligibilty: props.questionData?.isEligibilty || false,
            // isChinese: props.questionData?.isChinese || false,
            // allowInvestigator: props.questionData?.allowInvestigator || false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row add-education-wrapper">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Questionnaire Name *
                    </div>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Questionnaire Name in Chinese
                    </div>
                    <Input
                      name="name_ch"
                      value={values.name_ch}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                  </div>
                </div> */}
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Description
                    </div>
                    <Input
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="isEligibilty"
                      checked={values.isEligibilty}
                      onChange={(e) => {
                        setFieldValue("isEligibilty", e.target.checked);
                      }}
                    >
                      Screening Eligibility CRF
                    </Checkbox>
                  </div>
                </div> */}
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="isChinese"
                      checked={values.isChinese}
                      onChange={(e) => {
                        setFieldValue("isChinese", e.target.checked);
                      }}
                    >
                      Allow chinese labels
                    </Checkbox>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="allowInvestigator"
                      checked={values.allowInvestigator}
                      onChange={(e) => {
                        setFieldValue("allowInvestigator", e.target.checked);
                      }}
                    >
                      Data entry only by Investigator
                    </Checkbox>
                  </div>
                </div> */}
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Type
                    </div>
                    <Select
                      className="custom-select-field"
                      size="large"
                      value={values.category}
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        setFieldValue("category", val);
                      }}
                    >
                      <Select.Option value={1}>e-CRF</Select.Option>
                      <Select.Option value={2}>e-PRO</Select.Option>
                    </Select>
                  </div>
                </div> */}
                <div className="col-md-8 image-row mt-4">
                  <div className="text-secondary font-m fw-medium mb-4">
                    Upload questionnaire picture
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      {imgData.postUploadImageUrl !== "" ? (
                        <div className="image-container">
                          <img
                            src={imgData.postUploadImageUrl}
                            alt="question"
                          />
                        </div>
                      ) : values.imageUrl !== null && values.imageUrl !== "" ? (
                        <>
                          <div className="image-container">
                            <img src={values.imageUrl} alt="question" />
                          </div>
                        </>
                      ) : (
                        <div className="image-wrapper">
                          <div className="placeholder-icon"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ps-0 upload-wrapper">
                      <Input
                        disabled
                        placeholder={
                          imgData.fileName === ""
                            ? "No file selected"
                            : imgData.fileName
                        }
                      />
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={handleAvater}
                        accept=".gif,.jpg,.jpeg,.png"
                      >
                        <button type="button" className="upload-button">
                          Upload File
                        </button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {
                    "Upload an image of max 5 mb, accepted formats are .jpg,.jpeg,.png"
                  }
                </div>
                <div className="button-container mt-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">
                      {props.modalType === "EDIT_QUESTIONS" ? "Update" : "Save"}
                    </span>
                  </Button>
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddQuestions;
