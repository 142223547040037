import React from "react";
import Routes from "./routes";
import RootModal from "./components/Modals/RootModal";
import AppLoader, { Loader } from "./components/Common/Loader";

const App = () => {
  return (
    <main>
      <Routes />
      <React.Suspense fallback={<Loader />}>
        <RootModal />
      </React.Suspense>
      <AppLoader />
    </main>
  );
};

export default App;
