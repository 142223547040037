import React, { useState, useEffect } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../redux/actions/userAction";
import LogoContainer from "../Common/Logo-container";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerifyEmail = () => {
  const dispatch = useAppDispatch();
  const [verifying, setVerifying] = useState("pending");
  const tokenId = useQuery().get("tokenId");
  const emailId = useQuery().get("emailId");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyData = async () => {
      try {
        await dispatch(
          verifyEmail({
            email: emailId,
            emailVerifyToken: tokenId,
          })
        );
        setVerifying("completed");
        setTimeout(() => {
          navigate("/auth/login");
        }, 5000);
      } catch (err) {
        errorToastMessage(err as Error);
        setVerifying("error");
      }
    };
    if (emailId && tokenId) {
      verifyData();
    }
  }, [dispatch, setVerifying, emailId, tokenId]);

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />
              <div className="px-md-4">
                <div className="mobile-screen-icon"></div>
                <h1 className="font-l fw-bold mb-5">
                  {verifying === "pending" && "Verifying your email address"}
                  {verifying === "completed" &&
                    "Your email address has been verified"}
                  {verifying === "error" &&
                    "There was a problem verifying your email. Please try again"}
                </h1>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default VerifyEmail;
