import { useRef } from "react";
import composeRefs from "@seznam/compose-react-refs";
import { connect } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { Button, Input, Tooltip, Upload } from "antd";
import { textMap, IconMap, QuestionLabelMap } from "./questionTypes";
import { toastMessage } from "../../../helpers/toastMessage";
import QuestionGroup from "./QuestionGroup";
import { UploadImage } from "../../../helpers/imageUpload";
import * as types from "../../../redux/actionTypes";
import { v4 as uuid } from "uuid";
import {
  CogIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { CloudUploadIcon, DuplicateIcon } from "@heroicons/react/outline";

type Props = {
  choice: any;
  index: number;
  accepted: any;
  handleDrop: Function;
  duplicate: Function;
  duplicationGroupQ: Function;
  delete: Function;
  setInnerFields: Function;
  chooseField?: boolean;
  questions: any;
  UploadImage: Function;
  moveItem: Function;
  handleSequenceDrop: Function;
  orginalGroupQs?: any;
  originalGroupIndex?: any;
  questionnaireStatus: any;
  qid?: string;
  handleSettings: Function;
  showChinese: boolean;
};

const TextChoiceField = (props: Props) => {
  const ref = useRef<HTMLElement>(null);

  const { choice, index } = props;

  //drop from Q-list
  const [, drop] = useDrop({
    accept: props.accepted,
    drop: (dropItem: any) => {
      if (!props.chooseField) props.handleDrop(dropItem.type, props.index);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  //drag and drop to re-sequence
  const [{ isDragging }, drag] = useDrag({
    item: { ...choice, type: types.ITEM_TYPE },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropper] = useDrop({
    accept: types.ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item["position"] - 1;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition: any = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleMovingItem(dragIndex, hoverIndex);
      item.position = hoverIndex + 1;
    },
    drop: (item: any) => {
      const dragIndex = item["position"] - 1;
      const hoverIndex = props.index;
      props.handleSequenceDrop(dragIndex, hoverIndex);
    },
  });

  const handleMovingItem = (dragIndex: any, hoverIndex: any) => {
    props.moveItem(dragIndex, hoverIndex);
  };

  drag(dropper(ref));

  const addField = (idx?: number) => {
    const type = choice.fields[0].type;
    const toBeAdded = { type: type, id: uuid(), label: "", code: "" };

    // const prev = choice.fields.slice(0, idx + 1);
    // const next = choice.fields.slice(idx, -1);

    const newField = {
      ...choice,
      fields: [...choice.fields, toBeAdded],
    };

    props.setInnerFields(index, newField);
  };

  const removeField = (idx: number) => {
    const otherField = choice.fields?.find?.((choice: any) => {
      return choice.isOther;
    });
    if ((!otherField && choice.fields.length > 1) || choice.fields.length > 2) {
      const newField = {
        ...choice,
        fields: choice.fields.filter((_: any, i: number) => i !== idx),
      };
      props.setInnerFields(index, newField);
    }
  };

  const handleChange = (idx: number, e: any, outer?: boolean) => {
    if (!outer) {
      const newVal = {
        ...choice,
        fields: choice.fields.map((f: any, i: number) =>
          i === idx ? { ...f, [e.target.name]: e.target.value } : f
        ),
      };
      props.setInnerFields(index, newVal);
    } else {
      const newVal = {
        ...choice,
        [e.target.name]: e.target.value,
      };
      props.setInnerFields(index, newVal);
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleSettings = () => {
    props.handleSettings({
      index: props.index,
    });
  };

  const Icon = IconMap[choice.type];

  const renderFields = (field: any, idx: number) => {
    switch (field.type) {
      case "dropdown":
        if (field.isOther) {
          return (
            <div
              key={idx}
              style={{ marginTop: idx === 0 ? "40px" : "0px" }}
            ></div>
          );
        }
        return (
          <div
            className="field-group"
            key={idx}
            style={{ marginTop: idx === 0 ? "40px" : "0px" }}
          >
            <div className="checkboxes">
              <div className="d-flex flex-column" style={{ width: "50%" }}>
                <Input
                  name="label"
                  value={field.label}
                  className="singer-input"
                  onChange={(e) => handleChange(idx, e)}
                  placeholder={`${textMap[choice.type].text} ${idx + 1}`}
                />
                {props.showChinese && (
                  <Input
                    name="label_ch"
                    value={field.label_ch}
                    className="singer-input mt-4"
                    onChange={(e) => handleChange(idx, e)}
                    placeholder={`${textMap[choice.type].text} ${
                      idx + 1
                    } (Chinese)`}
                  />
                )}
              </div>
              <PlusIcon
                className="large-hero-icon text-gray cp"
                style={{ marginLeft: "30px" }}
                onClick={() => addField(idx)}
              />
              <TrashIcon
                className="large-hero-icon text-red cp"
                style={{ marginLeft: "30px" }}
                onClick={() => removeField(idx)}
              />
            </div>
          </div>
        );
      case "yes_no":
        return (
          <div
            className="field-group"
            key={idx}
            style={{ marginTop: idx === 0 ? "40px" : "0px" }}
          >
            <div className="checkboxes">
              <div className="d-flex flex-column" style={{ width: "50%" }}>
                <Input
                  name="label"
                  value={field.label}
                  className="singer-input"
                  disabled={true}
                />
                {props.showChinese && (
                  <Input
                    name="label_ch"
                    value={field.label_ch}
                    className="singer-input mt-4"
                    onChange={(e) => handleChange(idx, e)}
                    placeholder={`${idx === 0 ? "Yes" : "No"}  (Chinese)`}
                  />
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleImage = (fileObj: any) => {
    const handleUpload = (data: any) => {
      const newVal = {
        ...choice,
        attachment: {
          href: data[0].postUploadImageUrl,
          type: "image",
        },
      };
      props.setInnerFields(index, newVal);
    };
    props.UploadImage(fileObj, handleUpload, "questionnaire_image");
  };

  const clearImage = (event: any) => {
    event.stopPropagation();
    const newVal = {
      ...choice,
      attachment: {},
    };
    props.setInnerFields(index, newVal);
  };

  return (
    <div
      className={
        props.questionnaireStatus === "draft"
          ? "question-choice-field"
          : "question-choice-field disabled-div-qb"
      }
      // @ts-ignore
      ref={composeRefs(ref, drop)}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div className="d-flex cp">
        <Tooltip placement="left" title={QuestionLabelMap[choice.type]}>
          <div className="question-type">
            <span className="question-index">{index + 1}</span>
            {Icon && <Icon />}
          </div>
        </Tooltip>
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={handleImage}
          accept=".gif,.jpg,.jpeg,.png"
        >
          <Button
            style={{ height: "40px", width: "100px", borderRadius: "12px" }}
            className="me-4 question-picture"
            title="Image upload"
          >
            {choice.attachment?.href ? (
              <img
                src={choice.attachment?.href}
                alt="question"
                className="question-thumbnail"
              />
            ) : (
              <CloudUploadIcon className="large-hero-icon cp" />
            )}
            {choice.attachment?.href && (
              <XCircleIcon className="picture-clear" onClick={clearImage} />
            )}
          </Button>
        </Upload>
        <span style={{ flex: 1, minWidth: "0px" }}>
          <Input
            value={choice.title}
            name="title"
            className="singer-input"
            onChange={(e) => handleChange(index, e, true)}
            placeholder="Type here"
          />
          {props.showChinese && (
            <Input
              value={choice.title_ch}
              name="title_ch"
              className="singer-input mt-4"
              onChange={(e) => handleChange(index, e, true)}
              placeholder="Type here (Chinese)"
            />
          )}
        </span>
      </div>

      {choice.fields &&
        choice.fields.map((field: any, idx: number) =>
          renderFields(field, idx)
        )}

      {choice.type === "group" && (
        <QuestionGroup
          group={choice}
          accepted={props.accepted}
          handleDrop={props.handleDrop}
          index={index}
          setInnerFields={props.setInnerFields}
          questions={props.questions}
          questionnaireStatus={props.questionnaireStatus}
          duplicationGroupQ={props.duplicationGroupQ}
          moveItem={props.moveItem}
          handleSequenceDrop={props.handleSequenceDrop}
          qid={props.qid}
          handleSettings={props.handleSettings}
          showChinese={props.showChinese}
        />
      )}

      <div className="questionnaire-actions">
        <div className="me-3">
          <Tooltip placement="bottom" title="Configuration">
            <CogIcon
              className="large-hero-icon cp"
              style={{ color: "#71717A" }}
              onClick={handleSettings}
            />
          </Tooltip>
        </div>
        <div className="me-3">
          <Tooltip placement="bottom" title="Duplicate">
            <DuplicateIcon
              className="large-hero-icon text-gray cp"
              onClick={() => {
                if (props.orginalGroupQs && props.duplicationGroupQ) {
                  props.duplicationGroupQ(props.orginalGroupQs, choice);
                } else {
                  props.duplicate(index, choice.type);
                }
              }}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="bottom" title="Delete">
            <TrashIcon
              className="large-hero-icon text-red cp"
              onClick={() => props.delete(index)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { UploadImage })(TextChoiceField);
