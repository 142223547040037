import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoaderState {
  loader: boolean;
}

const initialState: LoaderState = {
  loader: false,
};

export const appointmentSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setAppLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  },
});

export const { setAppLoader } = appointmentSlice.actions;

export default appointmentSlice.reducer;
