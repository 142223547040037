import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd";
import { v4 as uuid } from "uuid";
import DropArea from "./DropArea";
import ChoiceItem from "./ChoiceItem";
import { choices, fieldMap } from "./questionTypes";
import {
  questionSubmitFormat,
  logicJumpFormat,
  questionResponseFormat,
} from "../../../helpers/question";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ChevronLeftIcon, EyeIcon } from "@heroicons/react/solid";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import QuestionPropertiesModal from "./QuestionPropertiesModal";
import { canAmendQuestions } from "../../../helpers/roles";

type Props = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionBuilder: React.FC<Props> = (props: Props) => {
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const role = useAppSelector((state) => state.user.role);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const name = query.get("name");
  const [questions, setQuestions] = useState<any[]>([]);
  const [questionnaireStatus, setStatus] = useState("");
  const [showChinese, setChinese] = useState(false);
  const [toggleLoad, setToggle] = useState(false);
  const [modal, setModal] = useState(null);
  const formDirty = useRef(false);

  const [hasAdminAccess] = useState(canAmendQuestions(role));

  useEffect(() => {
    const fetchDetails = async (id: any) => {
      if (qid) {
        try {
          dispatch(setAppLoader(true));
          let { data } = await http.get(`/questionnaires/${id}/questions`);
          let questions = await questionResponseFormat(
            data.data.questions,
            data.data.logicJumps
          );
          setQuestions(questions);
          setStatus(data.data.status);
          setChinese(data.data.isChinese);
          formDirty.current = false;
          dispatch(setAppLoader(false));
        } catch (err) {
          dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
          navigate(-1);
        }
      }
    };
    fetchDetails(qid);
  }, [qid, toggleLoad, dispatch, setQuestions, setStatus, setChinese]);

  const handleSettings = (modalProp: any) => {
    setModal(modalProp);
  };

  const handleDrop = (type: string, index: number) => {
    const itemPosition = questions.length + 1;
    let newItem: any = {
      ...fieldMap(type),
      position: itemPosition,
      type,
      id: uuid(),
    };
    if (type === "group") {
      newItem = {
        ...newItem,
        questions: [
          {
            ...fieldMap("short_text"),
            type: "short_text",
            position: 1,
            id: uuid(),
          },
        ],
      };
    }

    if (typeof index === "number") {
      const prev = questions.slice(0, +index + 1);
      const next = questions.slice(+index + 1, questions.length);
      const newQuestions = [...prev, newItem, ...next];
      setQuestions(newQuestions);
    } else {
      const newQuestions = [...questions, newItem];
      setQuestions(newQuestions);
    }
    formDirty.current = true;
  };

  const duplicateQuestion = (index: number, type: string) => {
    const prev = questions.slice(0, index + 1);
    const itemPosition = index + 2;
    const next = questions
      .slice(index + 1, questions.length)
      .map((q: any, i: number) => {
        return { ...q, position: itemPosition + i + 1 };
      });
    const newItem = {
      ...questions[index],
      position: itemPosition,
      id: uuid(),
      type: type,
    };

    setQuestions([...prev, newItem, ...next]);
    formDirty.current = true;
  };

  const duplicationGroupQ = (group: any, choice: any) => {
    let newGroup = group;

    let subGroupIndex = group.questions.findIndex(
      (x: any) => x.id === choice.id
    );

    const prev = group.questions.slice(0, subGroupIndex + 1);
    const itemPosition = subGroupIndex + 2;
    const next = group.questions
      .slice(subGroupIndex + 1, group.questions.length)
      .map((q: any, i: number) => {
        return { ...q, position: itemPosition + i + 1 };
      });

    const newItem = {
      ...choice,
      position: itemPosition,
      id: uuid(),
      type: choice.type,
    };

    newGroup.questions = [...prev, newItem, ...next];

    setQuestions(
      questions.map((ques) => {
        return ques.id === newGroup.id ? newGroup : ques;
      })
    );
    formDirty.current = true;
  };

  const deleteQuestion = (index: number) => {
    const newQuestions = questions.filter((_: any, i: number) => i !== index);
    setQuestions(newQuestions);
    formDirty.current = true;
  };

  const setInnerFields = (index: number, newField: any) => {
    const prev = questions.slice(0, index);
    const next = questions.slice(index + 1, questions.length);
    const newQuestions = [...prev, newField, ...next];
    setQuestions(newQuestions);
    formDirty.current = true;
  };

  const handleSubmit = async (status: string = "active") => {
    try {
      if (questions.length < 1) {
        throw new Error("You must have atleast one question.");
      }
      dispatch(setAppLoader(true));

      let ques = await questionSubmitFormat(questions);
      const logicJumps = await logicJumpFormat(questions);

      const formObject = {
        questions: ques,
        logicJumps: logicJumps,
        status,
      };
      const { data } = await http.post(
        `/questionnaires/${qid}/build`,
        formObject
      );
      dispatch(setAppLoader(false));
      toastMessage("success", data.message);
      setToggle((prev) => !prev);
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const handlePreview = async () => {
    if (questions.length < 1) {
      throw new Error("You must have atleast one questionnare.");
    }
    if (formDirty.current) {
      toastMessage(
        "warning",
        "Are you sure, you want to move to preview page without saving? You may lose the questions built"
      );
    } else {
      navigate(`/question/preview?id=${qid}&name=${name}`);
    }
  };

  const navigateScore = () => {
    if (questionnaireStatus === "active") {
      navigate(`/question/score?id=${qid}&name=${name}`);
    }
  };

  const moveItem = (dragIndex: any, hoverIndex: any) => {
    setQuestions((prevState) => {
      const ques = questions[dragIndex];
      const newItems = prevState.filter((item: any) => item.id !== ques.id);
      newItems.splice(hoverIndex, 0, ques);
      return [...newItems];
    });
    formDirty.current = true;
  };

  const handleSequenceDrop = (dragIndex: any, hoverIndex: any) => {
    setQuestions((prevState) => {
      const ques = questions[dragIndex];
      const newItems = prevState.filter((item: any) => item.id !== ques.id);
      newItems.splice(hoverIndex, 0, ques);
      let result = newItems.map((q: any, i: number) => {
        return { ...q, position: i + 1 };
      });
      return [...result];
    });
    formDirty.current = true;
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="question-modules question-builder">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-5 text-secondary cp"
          onClick={handleBack}
        />
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          {name || "Form Builder"}
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        {questionnaireStatus === "draft" && hasAdminAccess && (
          <Button
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={() => {
              handleSubmit("draft");
            }}
          >
            {/* <TrashIcon className="hero-small-icon me-2" /> */}
            <span className="fw-semibold font-sm">Draft</span>
          </Button>
        )}
        {questionnaireStatus && (
          <Button
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={handlePreview}
          >
            <EyeIcon className="hero-small-icon me-2" />
            <span className="fw-semibold font-sm">Preview</span>
          </Button>
        )}
        {questionnaireStatus === "active" && (
          <Button
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={navigateScore}
          >
            <span className="fw-semibold font-sm">Score</span>
          </Button>
        )}
        {questionnaireStatus === "draft" && (
          <>
            <Button
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={handleBack}
            >
              <span className="fw-semibold font-sm">Cancel</span>
            </Button>
            {hasAdminAccess && (
              <Button
                type="primary"
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
                onClick={() => handleSubmit()}
              >
                <span className="fw-semibold font-sm">Submit</span>
              </Button>
            )}
          </>
        )}
      </div>
      <div className="builder-interface">
        <div
          className={
            "choices p-4 " +
            (questionnaireStatus === "draft" ? "" : "disabled-div-qb")
          }
        >
          <div className="font-ml mb-2 text-secondary fw-bold">
            Select question type
          </div>
          <div className="font-sm text-gray mb-4">
            Drag and drop new question types to the left. Edit or rearrange them
            after.
          </div>
          <div className="choice-items">
            {choices.map((choice) => (
              <ChoiceItem key={choice.key} choice={choice} />
            ))}
          </div>
        </div>
        <DropArea
          questions={questions}
          handleDrop={handleDrop}
          duplicate={duplicateQuestion}
          duplicationGroupQ={duplicationGroupQ}
          delete={deleteQuestion}
          setInnerFields={setInnerFields}
          moveItem={moveItem}
          handleSequenceDrop={handleSequenceDrop}
          questionnaireStatus={questionnaireStatus}
          handleSettings={handleSettings}
          qid={qid}
          showChinese={showChinese}
        />
        {modal && (
          <QuestionPropertiesModal
            modalProp={modal}
            questions={questions}
            setModal={setModal}
            setInnerFields={setInnerFields}
            showChinese={showChinese}
          />
        )}
      </div>
    </div>
  );
};

const QuestionBuilderContext: React.FC<Props> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <QuestionBuilder {...props} />
    </DndProvider>
  );
};

export default QuestionBuilderContext;
