import React from "react";
import { Button } from "antd";

type Props = {
  disabled?: boolean;
  loading?: boolean;
  primary?: boolean;
  className?: string;
  buttonType?: "button" | "submit" | "reset";
  buttonText: string;
  addButton?: boolean;
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const CustomButton: React.FC<Props> = ({
  disabled = false,
  primary = true,
  loading = false,
  className = "",
  buttonText = "Submit",
  buttonType = "button",
  clickHandler,
  addButton = false,
}: Props) => {
  return (
    <Button
      htmlType={buttonType}
      loading={loading}
      className={`custom-button
      ${
        primary
          ? "primary-background font-white"
          : "secondary-background font-white"
      }
      ${className}`}
      disabled={disabled}
      onClick={clickHandler}
    >
      {addButton ? (
        <div className="d-flex align-items-center">
          <div className="add-white-icon"></div>
          <span className="">{buttonText}</span>{" "}
        </div>
      ) : (
        buttonText
      )}
    </Button>
  );
};

export default CustomButton;
