import { Button, Input } from "antd";
import { Formik } from "formik";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col } from "antd";
import * as yup from "yup";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { forgotPassword } from "../../redux/actions/userAction";
import { NavLink } from "react-router-dom";
import LogoContainer from "../Common/Logo-container";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
});

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let resultObj = {
        email: values.email,
      };
      await dispatch(forgotPassword(resultObj));
      setSubmitting(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />
              <div className="px-md-4">
                <h2 className="font-l fw-bold mb-5">Forgot Password?</h2>
                <p className="mb-3">
                  Don’t worry, just enter the email address you registered with
                  and we will send you a link to reset your password.
                </p>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <Input
                          type="text"
                          name="email"
                          placeholder="Enter your email address"
                          onChange={handleChange}
                          className="singer-input"
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <div className="input-error">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </div>
                      <div className="bottom-pos-btn-container">
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            height: "40px",
                            width: "100%",
                            borderRadius: "12px",
                          }}
                          loading={isSubmitting}
                        >
                          <span className="fw-semibold font-sm">
                            Send Password Reset Link
                          </span>
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <NavLink className="mt-3" to="/auth/login">
                          Sign in ?
                        </NavLink>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
