import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Dropdown, Menu } from "antd";

type Props = {
  editable: boolean;
  phases: any[];
  selectedPhase: any;
  selectPhase: Function;
  editPhase: Function;
  deletePhase: Function;
};

const Phases: React.FC<Props> = ({
  phases,
  selectPhase,
  selectedPhase,
  deletePhase,
  editPhase,
  editable,
}) => {
  const menu = (phase: any) => (
    <Menu style={{ borderRadius: "6px" }}>
      <Menu.Item
        key="edit"
        onClick={() => {
          editPhase(phase.id, phase.name);
        }}
      >
        <PencilIcon className="hero-small-icon me-3" />
        <span className="font-sm fw-medium">Edit Phase</span>
      </Menu.Item>
      <Menu.Item
        danger
        key="delete"
        onClick={() => {
          deletePhase(phase.id);
        }}
      >
        <TrashIcon className="hero-small-icon me-3" />
        <span className="font-sm fw-medium">Remove Phase</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {phases.map((phase) => {
        return (
          <div
            className={
              selectedPhase === phase.id
                ? "builder-block selected"
                : "builder-block"
            }
            onClick={() => selectPhase(phase.id)}
            key={phase.id}
          >
            <div className="text-truncate ms-2 font-m fw-medium">
              {phase.name}
            </div>
            {editable && (
              <Dropdown
                overlay={() => menu(phase)}
                overlayClassName="singer-dropdown"
              >
                <DotsVerticalIcon
                  className="hero-icon cp"
                  style={{ marginLeft: "auto", color: "#71717A" }}
                />
              </Dropdown>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Phases;
