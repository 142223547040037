import { notification } from "antd";
import axios from "axios";

const colors = {
  success: "#52c41a",
  error: "#EB5757",
  warning: "#FF8907",
  info: "#52c41a",
};

declare type NotificationType = "success" | "error" | "warning" | "info";

export const errorToastMessage = (error: Error) => {
  console.log(error);
  let message = "Something went wrong";
  if (axios.isAxiosError(error)) {
    if (error?.response) {
      message = error?.response?.data?.message;
    } else {
      message = "Something went wrong";
    }
  } else if (error.message && typeof error.message === "string") {
    message = error.message;
  }
  notification.error({
    message: message,
    // description: message,
    style: { borderLeft: `4px solid ${colors["error"]}`, marginTop: `16px` },
    duration: 3,
  });
};

export const toastMessage = (
  type: NotificationType,
  message: string,
  title?: string
) => {
  notification[type]({
    message: title || type,
    description: message,
    style: { borderLeft: `4px solid ${colors[type]}`, marginTop: `16px` },
    duration: 3,
  });
};
