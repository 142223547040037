import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decodeToken } from "../actions/userAction";

export interface UserState {
  username: string;
  role: string;
  isUserAuthenticated: boolean;
}

const initialState: UserState = {
  role: "",
  isUserAuthenticated: false,
  username: "",
};

const getIntialState = (): UserState => {
  let decoded: any = decodeToken();
  const role = decoded?.role || "";
  const token = localStorage.getItem("accessToken") || "";
  const username = localStorage.getItem("fullName") || "";
  return {
    ...initialState,
    role: role,
    isUserAuthenticated: !!(token && role),
    username: username,
  };
};

export const userSlice = createSlice({
  name: "user",
  initialState: getIntialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthenticated = action.payload;
    },
    userLogout: (state) => {
      state.isUserAuthenticated = false;
      state.role = "";
      state.username = "";
    },
  },
});

export const { setUserName, setRole, setAuthenticated, userLogout } =
  userSlice.actions;

export default userSlice.reducer;
