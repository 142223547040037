import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import AuthLayout from "../AuthLayout/AuthLayout";
import { Row, Col, Button, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { setYourPassword } from "../../redux/actions/userAction";
import LogoContainer from "../Common/Logo-container";
let schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("New Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const SetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let resultObj = {
        password: values.password,
        token: token,
      };
      await dispatch(setYourPassword(resultObj));
      navigate("/auth/login");
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />
              <div className="px-md-4">
                <div className="font-l fw-bold mb-5">Set your own password</div>
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-5">
                        <Input.Password
                          name="password"
                          placeholder="New Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="singer-input"
                        />
                        <div className="input-error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </div>
                      </div>
                      <div>
                        <Input.Password
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="singer-input"
                        />
                        <div className="input-error">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button
                          htmlType="submit"
                          style={{
                            height: "40px",
                            width: "100%",
                            borderRadius: "12px",
                          }}
                          loading={isSubmitting}
                          type="primary"
                        >
                          <span className="fw-semibold font-sm">
                            Set Password
                          </span>
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default SetPassword;
