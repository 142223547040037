export const scoresFromResponse = (nodes: any[]) => {
  let newArray: any[] = [];
  let position = 1;

  nodes.forEach((item) => {
    if (
      item.type === "paranthesis" ||
      item.type === "constant" ||
      item.type === "operator"
    ) {
      const res = {
        type: item.type,
        value: item.value,
        position: position,
      };
      position++;
      newArray.push(res);
    } else if (item.type === "question") {
      const res = {
        type: item.type,
        value: item.value,
        position: position,
      };
      position++;
      newArray.push(res);
    }
  });

  return newArray;
};

export const scoreEvalFromResponse = (conditions: any[]) => {
  let newArray: any[] = [];

  conditions.forEach((item) => {
    if (
      item.condition === "gt" ||
      item.condition === "gte" ||
      item.condition === "eq" ||
      item.condition === "not_eq"
    ) {
      const res = {
        condition: item.condition,
        minValue: item.minValue,
        score: item.score,
      };
      newArray.push(res);
    } else if (item.condition === "lt" || item.condition === "lte") {
      const res = {
        condition: item.condition,
        maxValue: item.maxValue,
        score: item.score,
      };
      newArray.push(res);
    } else if (item.condition === "between") {
      const res = {
        condition: item.condition,
        minValue: item.minValue,
        maxValue: item.maxValue,
        score: item.score,
      };
      newArray.push(res);
    }
  });
  return newArray;
};

//Chheck if two array of objects are equal

export const isEqual = (value: any, other: any) => {
  // Get the value type
  let type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  let valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  let otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  let compare = function (item1: any, item2: any) {
    // Get the object type
    let itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};
