//  Questions

export const ITEM_TYPE = "ITEM_TYPE";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const SET_PREVIEW_QUESTIONS = "SET_PREVIEW_QUESTIONS";
export const INFINITE_GET_QUESTIONS = "INFINITE_GET_QUESTIONS";
export const GET_QUESTIONS_FILTER = "GET_QUESTIONS_FILTER";
export const RESET_QUESTIONS_FILTER = "RESET_QUESTIONS_FILTER";
export const GET_QUESTIONS_LIST_PROJECTS = "GET_QUESTIONS_LIST_PROJECTS";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";

//scores
export const SET_SCORES_LIST = "SET_SCORES_LIST";
export const SEARCH_SCORES_LIST = "SEARCH_SCORES_LIST";
export const SEARCH_SCORES = "SEARCH_SCORES";
export const GET_SCORE_INFO = "GET_SCORE_INFO";
export const INFINITE_GET_SCORES = "INFINITE_GET_SCORES";

// documents
export const SET_DOCUMENT_FILE = "SET_DOCUMENT_FILE";
export const GET_DOCUMENTS_FILE = "GET_DOCUMENTS_FILE";
export const GET_FOLDER_DOCUMENTS_FILE = "GET_FOLDER_DOCUMENTS_FILE";
export const GET_FOLDER_DOCUMENTS_NAME = "GET_FOLDER_DOCUMENTS_NAME";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
