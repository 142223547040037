import { Button, Input, Select } from "antd";
import React from "react";
import { conditions } from "./questionTypes";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";

type Props = {
  question: any;
  modifyQuestion: Function;
  preview?: boolean;
};

const nameType: any = {
  lt: "maxValue",
  lte: "maxValue",
  eq: "minValue",
  gte: "minValue",
  gt: "minValue",
};

const stateObj: any = {
  lt: {
    condition: "lt",
    code: "",
    maxValue: "",
  },
  lte: {
    condition: "lte",
    code: "",
    maxValue: "",
  },
  between: {
    condition: "between",
    code: "",
    minValue: "",
    maxValue: "",
  },
  eq: {
    condition: "eq",
    code: "",
    minValue: "",
  },
  gte: {
    condition: "gte",
    code: "",
    minValue: "",
  },
  gt: {
    condition: "gt",
    code: "",
    minValue: "",
  },
};

const initState = {
  condition: "lt",
  code: "",
  maxValue: "",
};

const NumberType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { responseCodes } = question.properties;

  const handleCondition = (value: string, idx: number) => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.map((r: any, i: number) =>
          i === idx ? { ...stateObj[value] } : r
        ),
      },
    };
    modifyQuestion(newVal);
  };

  const handleInput = (e: any, idx: number) => {
    // const regex = /^[0-9\b]+$/;
    // if (e.target.value === "" || regex.test(e.target.value)) {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.map((r: any, i: number) =>
          i === idx ? { ...r, [e.target.name]: e.target.value } : r
        ),
      },
    };
    modifyQuestion(newVal);
    // }
  };

  const addField = () => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: [...responseCodes, initState],
      },
    };
    modifyQuestion(newVal);
  };

  const removeField = (idx: number) => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.filter((_: any, i: number) => i !== idx),
      },
    };
    modifyQuestion(newVal);
  };

  return (
    <div className="response-container">
      {responseCodes.length > 0 ? (
        <>
          <div className="row m-0 mb-4">
            <div className="col-4 px-1 text-secondary font-m fw-medium">
              <label>If answer is</label>
            </div>
            <div className="col-3 px-1 text-secondary font-m fw-medium offset-3">
              <label>Response Value</label>
            </div>
          </div>

          {responseCodes.map((code: any, idx: number) => {
            return (
              <div className="row m-0 mb-3 align-items-center" key={idx}>
                <div className="col-4 px-1">
                  <Select
                    defaultValue={code.condition}
                    onChange={(value) => handleCondition(value, idx)}
                    size="large"
                    className="response-choice-selector"
                  >
                    {conditions["NUM"].map(({ label, value }: any) => (
                      <Select.Option value={value} key={value + label}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="col-3 px-1">
                  {code.condition === "between" ? (
                    <div className="d-flex align-items-center">
                      <Input
                        name="minValue"
                        value={code["minValue"]}
                        placeholder="Min"
                        type="number"
                        onChange={(e) => handleInput(e, idx)}
                        maxLength={3}
                        readOnly={preview}
                        className="singer-input"
                      />
                      <span className="px-2">-</span>
                      <Input
                        name="maxValue"
                        value={code["maxValue"]}
                        placeholder="Max"
                        type="number"
                        onChange={(e) => handleInput(e, idx)}
                        maxLength={3}
                        readOnly={preview}
                        className="singer-input"
                      />
                    </div>
                  ) : (
                    <Input
                      name={`${nameType[code.condition]}`}
                      value={code[nameType[code.condition]]}
                      placeholder="Value"
                      type="number"
                      onChange={(e) => handleInput(e, idx)}
                      maxLength={3}
                      readOnly={preview}
                      className="singer-input"
                    />
                  )}
                </div>
                <div className="col-3 px-1">
                  <Input
                    name="code"
                    value={code.code}
                    onChange={(e) => handleInput(e, idx)}
                    placeholder="Code"
                    type="number"
                    readOnly={preview}
                    className="singer-input"
                  />
                </div>
                <div className="col-2">
                  <PlusIcon
                    className="large-hero-icon text-gray cp"
                    onClick={addField}
                  />
                  <TrashIcon
                    className="large-hero-icon text-red cp ms-3"
                    onClick={() => removeField(idx)}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={addField}
          >
            <span className="fw-semibold font-sm">Add Response code</span>
          </Button>
        </>
      )}
    </div>
  );
};

const ChoiceType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { fields } = question;

  const handleInput = (e: any, idx: number) => {
    const newVal = {
      ...question,
      fields: fields.map((f: any, i: number) =>
        i === idx ? { ...f, [e.target.name]: e.target.value } : f
      ),
    };
    modifyQuestion(newVal);
  };

  return (
    <div className="response-container">
      {fields.map((f: any, idx: number) => (
        <div className="choice-response" key={idx}>
          <div className="row my-2">
            <div className="col-7 text-secondary font-m fw-medium">
              If choice is
            </div>
            <div className="col-5 text-secondary font-m fw-medium">
              Response Code
            </div>
          </div>
          <div className="row">
            <div className="col-7">
              <Input
                className="singer-input"
                placeholder={`Choice ${idx + 1}`}
                value={f.isOther ? "Other option" : f.label}
                readOnly
              />
            </div>
            <div className="col-5">
              <Input
                name="code"
                value={f.code}
                onChange={(e) => handleInput(e, idx)}
                placeholder="Code"
                type="number"
                maxLength={3}
                readOnly={preview}
                className="singer-input"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const CommonType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { properties } = question;
  const changeHandler = (event: any) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: [{ code: event.target.value }],
      },
    };
    modifyQuestion(newQuestion);
  };

  return (
    <div className="response-container px-1">
      <div className="choice-response mb-4">
        <div className="font-m fw-medium text-secondary mb-4">
          Response code
        </div>
        <Input
          name="code"
          type="number"
          value={properties?.responseCodes[0]?.code}
          onChange={changeHandler}
          placeholder="Code"
          className="singer-input"
          readOnly={preview}
        />
      </div>
    </div>
  );
};

const responseType: any = {
  multiple_choice: ChoiceType,
  likert_scale: ChoiceType,
  checkbox: ChoiceType,
  yes_no: ChoiceType,
  number: NumberType,
  short_text: CommonType,
  long_text: CommonType,
  date: CommonType,
  date_time: CommonType,
  upload_file: CommonType,
  grid: CommonType,
};

const ResponseLogic: React.FC<Props> = ({
  question,
  modifyQuestion,
  preview,
}) => {
  const ResponseComponent = responseType[question.type];

  return (
    <div className="mt-4 mb-1">
      {responseType.hasOwnProperty(question.type) && (
        <ResponseComponent
          question={question}
          modifyQuestion={modifyQuestion}
          preview={preview}
        />
      )}
    </div>
  );
};

export default ResponseLogic;
