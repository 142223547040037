type Props = {
  name: any;
  handleExp: Function;
  questions: any[];
};

const ListScoreQuestions: React.FC<Props> = ({
  name,
  handleExp,
  questions,
}) => {
  return (
    <div className="score-questions-list">
      <div className="score-questions-header">{`${name} questions`}</div>
      <div className="score-questions-item">
        {questions.length > 0 &&
          questions.map((item: any, index: any) => {
            return (
              <div
                className="score-question-content row cp"
                key={index}
                onClick={() => handleExp(item)}
              >
                <div className="question-number col-md-2">
                  {item.questionNo}
                </div>
                <div className="question-title col-md-10">{item.title}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ListScoreQuestions;
