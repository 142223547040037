import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppointmentSlot, AppointmentCount } from "../../types";

export interface AppointmentState {
  slots: AppointmentSlot[];
  countData: AppointmentCount;
  timings: any;
}

const initialState: AppointmentState = {
  slots: [],
  countData: {
    todayCount: 0,
    weekCount: 0,
    unconfirmedCount: 0,
  },
  timings: {},
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setSlots: (state, action: PayloadAction<AppointmentSlot[]>) => {
      state.slots = action.payload;
    },
    setAppointmentCount: (state, action: PayloadAction<AppointmentCount>) => {
      state.countData.todayCount = action.payload.todayCount;
      state.countData.weekCount = action.payload.weekCount;
      state.countData.unconfirmedCount = action.payload.unconfirmedCount;
    },
    setTimings: (state, action: PayloadAction<any>) => {
      state.timings = action.payload;
    },
  },
});

export const { setSlots, setAppointmentCount, setTimings } =
  appointmentSlice.actions;

export default appointmentSlice.reducer;
