import React, { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Input, Modal } from "antd";
import AuthLayout from "../AuthLayout/AuthLayout";
import { useAppDispatch } from "../../redux/hooks";
import { loginForm } from "../../types";
import { loginAction } from "../../redux/actions/userAction";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import axios from "axios";
import LogoContainer from "../Common/Logo-container";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is Required"),
});

const confirm = Modal.confirm;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const loginHandler = async (values: loginForm, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      await dispatch(loginAction(values));
    } catch (err) {
      setSubmitting(false);
      if (axios.isAxiosError(err) && err?.response?.status === 409) {
        forceLogout(values);
      } else {
        errorToastMessage(err as Error);
      }
    }
  };

  useEffect(() => {
    const msg = localStorage.getItem("message");
    if (msg) {
      localStorage.removeItem("message");
      toastMessage("warning", msg);
    }
  }, []);

  const forceLogout = (data: any) => {
    const logout = async (logoutData: any) => {
      try {
        dispatch(setAppLoader(true));
        const body: any = {
          email: logoutData.email,
          password: logoutData.password,
        };
        let url = "/auth/force-logout";
        const res = await http.post(url, body);
        toastMessage("success", res.data.message);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    confirm({
      title: "Force Logout",
      content:
        "This account is being used in an another device/browser. Do you want to force logout the other session?",
      onOk() {
        logout(data);
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />

              <div className="px-md-4">
                <div className="font-l">Welcome back!</div>
                <div className="font-l fw-bold mb-5">Please Login...</div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values: loginForm, { setSubmitting }) => {
                    loginHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <Input
                            name="email"
                            placeholder="Your Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="singer-input"
                          />
                          <div className="input-error text-left">
                            {touched.email && errors.email}
                          </div>
                        </div>
                        <div>
                          <Input.Password
                            name="password"
                            placeholder="Your password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="singer-input"
                          />
                          <div className="input-error text-left">
                            {touched.password && errors.password}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-1 mb-3">
                          <NavLink to="/auth/forgot-password">
                            Forgot Password?
                          </NavLink>
                        </div>
                        <div className="mt-4">
                          <Button
                            htmlType="submit"
                            style={{
                              height: "40px",
                              width: "100%",
                              borderRadius: "12px",
                            }}
                            loading={isSubmitting}
                            type="primary"
                          >
                            <span className="fw-semibold font-sm">Login</span>
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
