import React from "react";
import { Button, Input, Modal } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { addAdministrator } from "../../../redux/actions/adminAction";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { getStudyId } from "../../../helpers/study";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
});

const AddAdminModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show, header, role } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      const body = {
        ...values,
        studyId: getStudyId(),
      };
      await dispatch(addAdministrator(body));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <Modal
      title={`Add ${header}`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            role: role,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="w-100 mb-4">
                <div className="text-secondary font-m fw-medium mb-1">
                  Email Address*
                </div>
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="singer-input"
                />
                <div className="input-error text-left mt-1">
                  {errors.email && touched.email && errors.email}
                </div>
              </div>
              {/* first name and last name */}
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="text-secondary font-m fw-medium mb-1">
                    First Name*
                  </div>
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.firstName && touched.firstName && errors.firstName}
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Last Name*
                  </div>
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.lastName && touched.lastName && errors.lastName}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  htmlType="submit"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                  type="primary"
                >
                  <span className="fw-semibold font-sm">Save</span>
                </Button>
                <Button
                  onClick={closeHandler}
                  style={{ height: "40px", borderRadius: "12px" }}
                  htmlType="button"
                >
                  <span className="fw-semibold font-sm">Cancel</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddAdminModal;
