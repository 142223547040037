export const getImageInitials = (firstName: string, lastName: string) => {
  let initial = "";
  initial += firstName && firstName[0] ? firstName[0].toUpperCase() : "";
  initial += lastName && lastName[0] ? lastName[0].toUpperCase() : "";
  return initial;
};

export const getImageInitialsFullname = (name: string) => {
  if (name) {
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    return getImageInitials(firstName, lastName);
  } else {
    return "";
  }
};

export const constructNotificationUrl = (notification: any) => {
  let url = "/";
  if (notification.eventId === "ecg_submitted") {
    sessionStorage.setItem("crf-phase", notification?.data?.phaseId);
    sessionStorage.setItem("crf-question", notification?.data?.questionnaireId);
    url = `/crf/screening/${notification?.data?.userId}?patientId=${notification?.data?.screenId}`;
  } else if (notification.eventId === "appointment_ahead") {
    url = `/appointments`;
  } else if (notification.eventId === "chat") {
    if (notification?.data?.groupName && notification?.data?.groupId) {
      url = `/chat/${notification?.data?.groupId}?group=${notification?.data?.groupName}`;
    } else if (notification?.data?.senderId && notification?.data?.senderName) {
      url = `/chat/${notification?.data?.senderId}?user=${notification?.data?.senderName}`;
    } else {
      url = `/participants`;
    }
  }
  return url;
};
