import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListScoreQuestions from "./listScoreQuestions";
import ScoreCalculator from "./scoreCalculator";
import ScoreValidation from "./scoreValidation";
import {
  scoresFromResponse,
  scoreEvalFromResponse,
  isEqual,
} from "../../helpers/score";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { Button, Input } from "antd";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { useAppDispatch } from "../../redux/hooks";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateStore: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const propName = query.get("name");
  const scoreId = query.get("scoreId");

  const [name, setName] = useState("");
  let [scoreEval, setEval] = useState<any[]>([]);
  const [scoreInfo, setScoreInfo] = useState<any>(null);
  let [scores, setScores] = useState<any[]>([]);

  const [values, setValues] = useState<{ clickedItem: any; editItem: boolean }>(
    {
      clickedItem: {},
      editItem: false,
    }
  );

  const [questions, setQuestions] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchDetails = async () => {
      if (qid) {
        try {
          dispatch(setAppLoader(true));
          let { data } = await http.get(`/questionnaires/${qid}/questions`);
          let ques: any[] = [];
          const temp = data.data.questions;
          temp.sort(function (a: any, b: any) {
            return a.position - b.position;
          });
          temp.forEach((que: any) => {
            if (que.type !== "group") {
              ques.push({
                questionId: que.id,
                title: que.title,
                questionNo: `Q${que.position}`,
              });
            } else {
              const groupQues = que?.properties?.questions || [];
              groupQues.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              groupQues.forEach((q: any) => {
                ques.push({
                  questionId: q.id,
                  title: q.title,
                  questionNo: `Q${que.position}.${q.position}`,
                });
              });
            }
          });
          setQuestions(ques);
          dispatch(setAppLoader(false));
        } catch (err) {
          dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
        }
      }
    };
    fetchDetails();
  }, [qid, setQuestions, dispatch]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (scoreId) {
        try {
          dispatch(setAppLoader(true));
          const res = await http.get(`/score_expressions/${scoreId}`);
          const info = res.data.data;
          setScoreInfo(info);
          setScores(scoresFromResponse(info.scoreExpressionNodes));
          setEval(scoreEvalFromResponse(info.scoreExpressionConditions));
          setName(info.title);
          dispatch(setAppLoader(false));
        } catch (err) {
          dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
        }
      }
    };
    fetchDetails();
  }, [scoreId, setScoreInfo, setName, setScores, setEval, dispatch]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  // add question or operator to expression
  const handleExpression = (item: any) => {
    let expressionType = item.hasOwnProperty("questionId")
      ? "question"
      : item.hasOwnProperty("type")
      ? "paranthesis"
      : "operator";
    let expressionValue = item.hasOwnProperty("func")
      ? item.func
      : item.questionId;

    if (expressionValue === "AC") {
      setScores([]);
    } else {
      if (!values.editItem) {
        if (expressionValue === "constant") {
          setScores((prevState) => [
            ...prevState,
            {
              type: "constant",
              value: 100,
              position: prevState.length + 1,
            },
          ]);
        } else {
          setScores((prevState) => [
            ...prevState,
            {
              type: expressionType,
              value: expressionValue,
              position: prevState.length + 1,
            },
          ]);
        }
      } else {
        let tempArray =
          expressionValue === "constant"
            ? [
                {
                  type: "constant",
                  value: 100,
                  position: 1,
                },
              ]
            : [
                {
                  type: expressionType,
                  value: expressionValue,
                  position: 1,
                },
              ];

        let newArr = scores;
        let position = Number(values.clickedItem["position"]);
        newArr.splice(position, 0, ...tempArray);
        newArr.forEach((item, index) => (item.position = index + 1));

        setScores(newArr);
        setValues((prevState) => ({
          ...values,
          clickedItem: { ...prevState.clickedItem, position: position + 1 },
        }));
      }
    }
  };

  // on click of question or operator in expression
  const handleEditExpression = (item: any) => {
    setValues({
      ...values,
      clickedItem: item,
      editItem: true,
    });
  };

  // on removal of operand
  const handleRemoveOperand = (item: any) => {
    let newArray = scores.filter((score: any) => {
      return score.position !== item.position;
    });

    newArray.forEach((item, index) => (item.position = index + 1));
    setScores(newArray);
    setValues({
      ...values,
      clickedItem: {},
      editItem: false,
    });
  };

  //handle constants
  const handleConstants = (item: any, val: any) => {
    let newArray = scores.map((score: any) => {
      return score.position === item.position
        ? {
            ...score,
            value: Number(val),
          }
        : score;
    });

    setScores(newArray);
  };

  // SCORE EVALUATION

  // Add score and evaluate
  const handleUpdateScore = (
    idx: number,
    type: string,
    key: string,
    value: any
  ) => {
    if (type === "ADD") {
      setEval([
        ...scoreEval,
        {
          condition: "",
          score: 0,
        },
      ]);
    } else if (type === "CHANGE") {
      let newScoreEval = scoreEval.map((f, i) =>
        i === idx ? { ...f, [key]: value } : f
      );
      setEval(newScoreEval);
    }
  };

  // remove score object from the eval array
  const handleRemoveScore = (idx: any) => {
    const FilteredArray = scoreEval.filter(
      (_: any, index: number) => index !== idx
    );
    setEval(FilteredArray);
  };

  const updateScore = async (body: any) => {
    try {
      const res = await http.put(`/score_expressions/${scoreId}`, body);
      toastMessage("success", res.data.message);
      navigate(-1);
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  // Submit score exp with nodes and conditions
  const handleScoreSubmit = async (givenStatus: any) => {
    try {
      dispatch(setAppLoader(true));
      let updatedEval = scoreEval.map((item: any, index) => {
        return { ...item, position: index + 1 };
      });
      if (scoreId) {
        let body = {
          title: name,
          description: name,
          status: givenStatus,
        };
        updateScore(body);
      } else {
        let body = {
          title: name,
          description: name,
          status: givenStatus,
          questionnaireId: qid,
          scoreExpressionNodes: scores,
          scoreExpressionConditions: updatedEval,
        };
        const res = await http.post(`/score_expressions`, body);
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        navigate(-1);
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  //update score exp with nodes and conditions
  const handleScoreUpdate = (givenStatus: any) => {
    let updatedEval = scoreEval.map((item: any, index) => {
      return { ...item, position: index + 1 };
    });

    let scoreChange = isEqual(
      scores,
      scoresFromResponse(scoreInfo.scoreExpressionNodes)
    );
    let evalChange = isEqual(
      scoreEval,
      scoreEvalFromResponse(scoreInfo.scoreExpressionConditions)
    );
    let body;
    if (!scoreChange && evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
      };
    } else if (scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionConditions: updatedEval,
      };
    } else if (!scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
        scoreExpressionConditions: updatedEval,
      };
    } else if (name !== scoreInfo.title) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
      };
    } else {
      toastMessage("warning", "No changes to Update");
      return;
    }
    updateScore(body);
  };

  //cancel score exp with nodes and condition

  const handleCancelScore = () => {
    if (scoreInfo.hasOwnProperty("id") && scoreId) {
      setScores(scoresFromResponse(scoreInfo.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(scoreInfo.scoreExpressionConditions));
      setName(scoreInfo.title);
    } else {
      setScores([]);
      setEval([]);
      setName("");
    }
  };

  const navigateBack = () => {
    navigate(`/question/score?id=${qid}&name=${propName}`);
  };

  return (
    <div className="create-score">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-3 text-secondary cp"
          onClick={navigateBack}
        />
        <div className="font-m fw-semibold font-l text-secondary">{`${propName} Score`}</div>
      </div>
      <div className="score-content">
        <div className="px-1 mb-4" style={{ maxWidth: "300px" }}>
          <label className="form-label ">Score Name* :</label>
          <Input
            name="name"
            placeholder="Enter the score name"
            onChange={(e) => setName(e.target.value)}
            className="singer-input"
            value={name}
          />
        </div>
        <div className="score-main row">
          <div className="col-md-4">
            <ListScoreQuestions
              name={propName}
              handleExp={handleExpression}
              questions={questions}
            />
          </div>
          <div className="col-md-4">
            <ScoreCalculator
              scores={scores}
              handleExp={handleExpression}
              handleEditExp={handleEditExpression}
              handleRemoveOperand={handleRemoveOperand}
              handleConstants={handleConstants}
              questions={questions}
            />
          </div>
          <div className="col-md-4">
            <ScoreValidation
              updateScore={handleUpdateScore}
              scoreEval={scoreEval}
              handleRemoveScore={handleRemoveScore}
            />
          </div>
        </div>
        <div className="text-end mt-5">
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={handleCancelScore}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
          {scoreInfo?.status === "draft" && scoreId ? (
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={() => handleScoreUpdate("draft")}
            >
              <span className="fw-semibold font-sm">Update draft</span>
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={() => handleScoreSubmit("draft")}
            >
              <span className="fw-semibold font-sm">Save as draft</span>
            </Button>
          )}
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={() => handleScoreSubmit("active")}
          >
            <span className="fw-semibold font-sm">Submit</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateStore;
