import { Modal, Table } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";

const CRFSubmissionLogModal = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const { show, qid, phaseId, id } = useAppSelector(
    (state) => state.modal.modalProps
  );

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/questionnaires/${qid}/submissions?phaseId=${phaseId}&userId=${id}`
        );
        const responses = res.data.data.map((instance: any) => {
          return {
            id: instance.id,
            phaseId: instance.phaseId,
            questionnaireId: instance.questionnaireId,
            version: instance.version,
            isDraft: instance.remark === "draft",
            createdAt: moment(instance.createdAt).format("MM/DD/YYYY"),
            user: instance?.who
              ? instance?.who?.firstName + " " + instance?.who?.lastName
              : "-",
          };
        });
        setData(responses);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchDetails();
  }, [setData, dispatch, qid, phaseId, id]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const loadResponse = useCallback(
    async (version: any) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/questionnaires/${qid}/scores?phaseId=${phaseId}&userId=${id}&version=${version}`
        );
        const scores = res.data.data.map((score: any) => {
          return {
            score: score.score,
            title: score.scoreExpression?.title,
            id: score.id,
          };
        });
        dispatch(
          setModalDetails({
            type: "CRF_SCORE_MODAL",
            modalProps: {
              show: true,
              scores: scores,
              qid: qid,
              phaseId: phaseId,
              id: id,
              version: version,
            },
          })
        );
        dispatch(setAppLoader(false));
      } catch (error) {
        dispatch(setAppLoader(false));
        errorToastMessage(error as Error);
      }
    },
    [dispatch, qid, phaseId, id]
  );

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Admin user",
        dataIndex: "user",
        key: "user",
        align: "center",
      },
      {
        title: "Completed Date",
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
      },
      {
        title: "View Score",
        render: (_1: any, record: any) => {
          return !record.isDraft ? (
            <div
              onClick={() => loadResponse(record.version)}
              className="color-primary cp"
            >
              View Score
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
    ];
  }, [loadResponse]);

  return (
    <Modal
      title={"CRF Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <Table
        dataSource={data}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default CRFSubmissionLogModal;
