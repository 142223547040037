import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { AxiosResponse } from "axios";
import http from "../../../http";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
});

const EditAdminModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show, callback } = useAppSelector((state) => state.modal.modalProps);
  const [data, setData] = useState<any>(null);
  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const id = localStorage.getItem("userId");
      const res: AxiosResponse = await http.patch(`/admins/${id}`, {
        firstName: values.firstName,
        lastName: values.lastName,
      });
      const res1: AxiosResponse = await http.get(`/admins/${id}`);
      const response = res1.data.data;
      const name = `${response.firstName} ${response.lastName}`;
      localStorage.setItem("fullName", name);
      toastMessage("success", res.data.message);
      callback();
      dispatch(setAppLoader(false));
      dispatch(hideModal());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setAppLoader(true));
        const id = localStorage.getItem("userId");
        const res: AxiosResponse = await http.get(`/admins/${id}`);
        const response = res.data.data;
        setData({
          firstName: response.firstName,
          lastName: response.lastName,
        });
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err);
        dispatch(hideModal());
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <Modal
      title={`Edit Profile`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={{
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
          }}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="text-secondary font-m fw-medium mb-1">
                    First Name*
                  </div>
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.firstName && touched.firstName && errors.firstName}
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Last Name*
                  </div>
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.lastName && touched.lastName && errors.lastName}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  htmlType="submit"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                  type="primary"
                >
                  <span className="fw-semibold font-sm">Save</span>
                </Button>
                <Button
                  onClick={closeHandler}
                  style={{ height: "40px", borderRadius: "12px" }}
                  htmlType="button"
                >
                  <span className="fw-semibold font-sm">Cancel</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditAdminModal;
