import React from "react";
import ParticpantHeader from "./ParticipantHeader";
// import { Tabs } from "antd";
// import PreScreenedList from "./ParticipantsList/PreScreenedList";
import ScreenedList from "./ParticipantsList/ScreenedList";
import ParticipantUrlSetter from "./ParticipantUrlSetter";
import ParticipantUrlLoader from "./ParticipantUrlLoader";
import {
  // useAppDispatch,
  useAppSelector,
} from "../../redux/hooks";
// import { setParticipantsTab } from "../../redux/reducers/participantSlice";

// const { TabPane } = Tabs;

const Participants: React.FC = () => {
  // const dispatch = useAppDispatch();
  const {
    urlLoaded,
    //  selectedTab
  } = useAppSelector((state) => state.participant);

  // const tabChangeHandler = (key: string) => {
  //   dispatch(setParticipantsTab(key));
  // };

  return urlLoaded ? (
    <div className="cms-wrapper">
      <ParticpantHeader />

      {/* <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
      >
        <TabPane tab="Pre-Screening Participants" key="pre-screened"> */}
      {/* <PreScreenedList /> */}
      {/* </TabPane>
        <TabPane tab="Screened Participants" key="screened"> */}
      <ScreenedList />
      {/* </TabPane>
      </Tabs> */}
      <ParticipantUrlSetter />
    </div>
  ) : (
    <ParticipantUrlLoader />
  );
};

export default Participants;
