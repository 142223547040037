import React, { useState } from "react";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Button, Input } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { getStudyId } from "../../../helpers/study";

type Props = {
  show: boolean;
  phaseName: string;
  phaseId: string;
  callback: Function;
};

const AddPhaseModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(props?.phaseName || "");

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async () => {
    try {
      if (!name.trim()) {
        toastMessage("warning", "Phase name cannot be empty");
        return;
      }
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      if (!props.phaseId) {
        await http.post(`/study/${studyId}/phase`, {
          name,
          duration: 1,
        });
      } else {
        await http.patch(`/study/${studyId}/phase/${props.phaseId}`, {
          name,
          duration: 1,
        });
      }
      closeHandler();
      props.callback();
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  return (
    <Modal
      title={"Add Phase"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group">
            <div className="text-secondary font-m fw-medium mb-1">
              Phase Title
            </div>
            <Input
              name="phase"
              placeholder="Title"
              onChange={handleChange}
              className="singer-input"
              value={name}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default AddPhaseModal;
