import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { useAppDispatch } from "../../redux/hooks";
import { Input, Button, Row, Col } from "antd";
import { errorToastMessage } from "../../helpers/toastMessage";
import { surveyHttp } from "../../http";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../AuthLayout/AuthLayout";
import LogoContainer from "../Common/Logo-container";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
});

const ResumeSurvey: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const body = {
        ...values,
      };
      await surveyHttp.post(`/users/resend-survey`, body);
      // toastMessage("success", res.data.message);
      dispatch(setAppLoader(false));
      navigate("/thank-you");
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />
              <div className="">
                <div className="font-l">Welcome to Mahalo!</div>
                <div className="font-l fw-bold mb-2">
                  Please enter your email address, and we'll send you a link to
                  continue with your survey.
                </div>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    submitHandler(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div>
                            <Input
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                              className="singer-input"
                              value={values.email}
                              onBlur={handleBlur}
                            />
                            <div className="input-error">
                              {errors.email && touched.email && errors.email}
                            </div>
                          </div>
                        </div>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            height: "40px",
                            borderRadius: "12px",
                            width: "100%",
                          }}
                          className="me-4 mt-4"
                        >
                          <span className="fw-semibold font-sm">
                            Send me my survey link
                          </span>
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default ResumeSurvey;
