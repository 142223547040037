import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { surveyHttp } from "../../http";
import { errorToastMessage } from "../../helpers/toastMessage";
import Survey from "../CRF/Survey/Survey";
import { Loader } from "../Common/Loader";
import ParticipantPathway from "../ParticipantPathway/ParticipantPathway";
import InfoPage from "./Info";

const SurveyWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [pathway, setPathway] = useState(false);
  const [showInfo, setShowInfo] = useState(
    sessionStorage.getItem("hide-survey-info") ? false : true
  );

  useEffect(() => {
    const fetchStudy = async () => {
      try {
        const res = await surveyHttp.post(
          `/users/status`,
          {},
          {
            headers: {
              Authorization: "Bearer " + id,
            },
          }
        );
        if (!res.data.data.enableSurveyEdit) {
          navigate("/thank-you?complete=true");
        }
        setPathway(res.data.data.isPathwaySet);
        setError("");
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setError("We are experiencies some issue, please try again later");
        setLoading(false);
      }
    };
    fetchStudy();
  }, [setLoading, setError, id]);

  const proceedToSurvey = () => {
    setPathway(true);
  };

  const hideInfo = () => {
    sessionStorage.setItem("hide-survey-info", "true");
    setShowInfo(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="thank-you-container">
          <div className="title-desc">{error}</div>
        </div>
      ) : pathway ? (
        showInfo ? (
          <InfoPage closeHandler={hideInfo} />
        ) : (
          <Survey />
        )
      ) : (
        <ParticipantPathway refresh={proceedToSurvey} />
      )}
    </>
  );
};

export default SurveyWrapper;
