import { useEffect, useState } from "react";
import { Modal, Avatar, Menu, Dropdown } from "antd";
import { authLogout } from "../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
import { LogoSidebar } from "../../components/AppLayout/SidebarIcons";
import { getImageInitialsFullname } from "../../helpers/user";
import { useAppDispatch } from "../../redux/hooks";
import { setModalDetails } from "../../redux/reducers/modalSlice";

const confirm = Modal.confirm;

// const playAudio = () => {
//   try {
//     const audio = new Audio("/Notification.mp3");
//     audio.play();
//   } catch (err) {}
// };

const Navigation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState<string | null>("");
  const [name, setName] = useState("");
  // const [toggleNotification, setToggleNotification] = useState(false);

  // const [unread, setUnread] = useState(0);
  // const [notifications, setNotifications] = useState<any[]>([]);
  // const [showNotificationDropdown, setShowNotification] = useState(false);
  // const [notificationPage, setNotificationPage] = useState(1);
  // const [total, setTotal] = useState(0);

  // const [chatUnread, setChatUnread] = useState(0);
  // const [chatNotifications, setChatNotifications] = useState<any[]>([]);
  // const [showChatNotificationDropdown, setChatShowNotification] =
  //   useState(false);
  // const [chatNotificationPage, setChatNotificationPage] = useState(1);
  // const [chatTotal, setChatTotal] = useState(0);
  const [toggleName, setToggleName] = useState(false);

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const res = await http.get(
  //         `/notifications/chat?page=${chatNotificationPage}&size=8`
  //       );
  //       const newNotifications = res.data.data.notifications.map(
  //         (item: any) => {
  //           const date = moment(item.sentAt);
  //           const time = moment().isSame(date, "day")
  //             ? date.format("hh:mm A")
  //             : date.format("DD/MM/YYYY hh:mm A");
  //           return {
  //             id: item.id,
  //             data: item.data,
  //             eventId: item.eventId,
  //             isRead: item.isRead,
  //             title: item.title,
  //             time,
  //           };
  //         }
  //       );
  //       setChatNotifications(newNotifications);
  //       setChatUnread(res.data.data.unreadCount);
  //       setChatTotal(res.data.data.count);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchNotifications();
  // }, [
  //   toggleNotification,
  //   chatNotificationPage,
  //   setChatUnread,
  //   setChatNotifications,
  //   setChatTotal,
  // ]);

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const res = await http.get(
  //         `/notifications?page=${notificationPage}&size=8`
  //       );
  //       const newNotifications = res.data.data.notifications.map(
  //         (item: any) => {
  //           const date = moment(item.sentAt);
  //           const time = moment().isSame(date, "day")
  //             ? date.format("hh:mm A")
  //             : date.format("DD/MM/YYYY hh:mm A");
  //           return {
  //             id: item.id,
  //             data: item.data,
  //             eventId: item.eventId,
  //             isRead: item.isRead,
  //             title: item.title,
  //             time,
  //           };
  //         }
  //       );
  //       setNotifications(newNotifications);
  //       setUnread(res.data.data.unreadCount);
  //       setTotal(res.data.data.count);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchNotifications();
  // }, [
  //   toggleNotification,
  //   notificationPage,
  //   setUnread,
  //   setNotifications,
  //   setTotal,
  // ]);

  // useEffect(() => {
  //   dispatch(initialiseFCM());
  // }, [dispatch]);

  // useEffect(() => {
  //   const listener = (event: any) => {
  //     if (event.data.message === "notification_received") {
  //       setToggleNotification((prev) => !prev);
  //     } else if (event.data.message === "notification_sound") {
  //       playAudio();
  //     }
  //   };
  //   try {
  //     if (messaging) {
  //       messaging.onMessage((payload: any) => {
  //         setToggleNotification((prev) => !prev);
  //         playAudio();
  //       });
  //       navigator.serviceWorker.addEventListener("message", listener);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   return () => {
  //     navigator.serviceWorker.removeEventListener("message", listener);
  //   };
  // }, [setToggleNotification]);

  // const handlePageChange = useCallback(
  //   (page: number) => {
  //     setNotificationPage(page);
  //   },
  //   [setNotificationPage]
  // );

  // const handleChatPageChange = useCallback(
  //   (page: number) => {
  //     setChatNotificationPage(page);
  //   },
  //   [setChatNotificationPage]
  // );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const img = localStorage.getItem("profileImage");
      setImage(img);
      const name = localStorage.getItem("fullName") || "";
      setName(name);
    }
  }, [setImage, setName, toggleName]);

  const showConfirm = () => {
    const onOk = async () => {
      await dispatch(authLogout());
    };
    confirm({
      title: "Logout",
      content: "Are you sure you want to logout ?",
      onOk() {
        onOk();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const showProfile = () => {
    dispatch(
      setModalDetails({
        type: "EDIT_PROFILE",
        modalProps: {
          show: true,
          callback: () => {
            setToggleName((prev) => !prev);
          },
        },
      })
    );
  };

  const Logout = (
    <Menu>
      <Menu.Item onClick={() => showProfile()} key="edit-profile">
        Edit Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate("/auth/change-password")}
        key="change-password"
      >
        Change password
      </Menu.Item>
      <Menu.Item onClick={showConfirm} key="logout" danger>
        Log out
      </Menu.Item>
    </Menu>
  );

  // const markAsRead = useCallback(
  //   async (id) => {
  //     try {
  //       dispatch(setAppLoader(true));
  //       await http.put("/notifications/" + id, {
  //         isRead: true,
  //       });
  //       setToggleNotification((prev) => !prev);
  //       dispatch(setAppLoader(false));
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       dispatch(setAppLoader(false));
  //     }
  //   },
  //   [setToggleNotification, dispatch]
  // );

  // const markAllAsRead = useCallback(async () => {
  //   try {
  //     dispatch(setAppLoader(true));
  //     await http.put("/notifications/mark-read");
  //     setToggleNotification((prev) => !prev);
  //     dispatch(setAppLoader(false));
  //   } catch (err) {
  //     errorToastMessage(err as Error);
  //     dispatch(setAppLoader(false));
  //   }
  // }, [setToggleNotification, dispatch]);

  // const markChatAllAsRead = useCallback(async () => {
  //   try {
  //     dispatch(setAppLoader(true));
  //     await http.put("/notifications/mark-read/chat");
  //     setToggleNotification((prev) => !prev);
  //     dispatch(setAppLoader(false));
  //   } catch (err) {
  //     errorToastMessage(err);
  //     dispatch(setAppLoader(false));
  //   }
  // }, [setToggleNotification, dispatch]);

  // const handleNavigation = useCallback(
  //   async (notification: any) => {
  //     await markAsRead(notification.id);
  //     const url = constructNotificationUrl(notification);
  //     navigate(url);
  //     setShowNotification(false);
  //     // setChatShowNotification(false);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [
  //     markAsRead,
  //     setShowNotification,
  //     //  setChatShowNotification
  //   ]
  // );

  // const content = (
  //   <NotificationDropDown
  //     notifications={notifications}
  //     markAsRead={markAsRead}
  //     handleNavigation={handleNavigation}
  //     handlePageChange={handlePageChange}
  //     total={total}
  //     current={notificationPage}
  //   />
  // );

  // const ChatContent = (
  //   <NotificationDropDown
  //     notifications={chatNotifications}
  //     markAsRead={markAsRead}
  //     handleNavigation={handleNavigation}
  //     handlePageChange={handleChatPageChange}
  //     total={chatTotal}
  //     current={chatNotificationPage}
  //   />
  // );

  return (
    <div className="navigation">
      <div className="logo-container d-block d-md-none m-3">
        <a href="/">
          <LogoSidebar />
        </a>
      </div>
      <div className="actions">
        {/* <Popover
          content={ChatContent}
          title={() => {
            return (
              <div className="d-flex align-items-center justify-content-between">
                <span>Chat Notifications</span>
                {chatUnread > 0 && (
                  <span
                    className="ms-2 font-sm cp color-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      markChatAllAsRead();
                    }}
                  >
                    Mark All as read
                  </span>
                )}
              </div>
            );
          }}
          overlayClassName="notification-dropdown"
          trigger="click"
          zIndex={100}
          visible={showChatNotificationDropdown}
          onVisibleChange={(val) => setChatShowNotification(val)}
        >
          <div className="action-button d-md-block border-start border-light cp">
            <Badge count={chatUnread} size={"small"}>
              <ChatBellIcon />
            </Badge>
          </div>
        </Popover> */}
        {/* <Popover
          content={content}
          title={() => {
            return (
              <div className="d-flex align-items-center justify-content-between">
                <span>Notifications</span>
                {unread > 0 && (
                  <span
                    className="ms-2 font-sm cp color-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      markAllAsRead();
                    }}
                  >
                    Mark All as read
                  </span>
                )}
              </div>
            );
          }}
          overlayClassName="notification-dropdown"
          trigger="click"
          zIndex={100}
          visible={showNotificationDropdown}
          onVisibleChange={(val) => setShowNotification(val)}
        >
          <div className="action-button d-md-block border-start border-light cp">
            <Badge count={unread} size={"small"}>
              <BellIcon />
            </Badge>
          </div>
        </Popover> */}
        <div className="user">
          <Dropdown placement="bottomRight" overlay={Logout}>
            <div className="d-flex align-items-center cp">
              <h6 className="d-flex m-0 pe-2">{name}</h6>
              <div className="notification d-none d-md-flex">
                {image !== "" ? (
                  <div className="image-container-nav user-icon-img">
                    <Avatar shape="circle" src={image} />
                  </div>
                ) : (
                  <Avatar shape="circle">
                    {getImageInitialsFullname(name)}
                  </Avatar>
                )}
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
