import { useAppSelector } from "../../redux/hooks";

export const Loader = () => {
  return (
    <div className="app-loader">
      <div className="spinner">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    </div>
  );
};

const AppLoader = () => {
  const { loader } = useAppSelector((state) => state.loader);

  if (loader) return <Loader />;
  else {
    return null;
  }
};

export default AppLoader;
