import Sidebar from "./Sidebar";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import { useAppSelector } from "../../redux/hooks";

const { Content, Sider } = Layout;

const AppLayout: React.FC = () => {
  const authenticated = useAppSelector(
    (state) => state.user.isUserAuthenticated
  );

  return (
    <>
      <Layout className={`singer-layout`}>
        <Sider
          breakpoint="md"
          collapsedWidth="0"
          width="80"
          id="custom-scrollbar"
          style={{
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <Sidebar />
        </Sider>
        <Layout>
          <Content className="content right-panel">
            {authenticated ? <Navigation /> : null}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
