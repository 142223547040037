import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { useAppDispatch } from "../../redux/hooks";
import { Input, Button, Row, Col, Radio } from "antd";
import { errorToastMessage } from "../../helpers/toastMessage";
import { surveyHttp } from "../../http";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import AuthLayout from "../AuthLayout/AuthLayout";
import { useParams } from "react-router-dom";
import LogoContainer from "../Common/Logo-container";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  // pathway: yup.string().required("Pathway is Required"),
});

type Props = {
  refresh: Function;
};

const ParticipantPathway: React.FC<Props> = ({ refresh }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const body = {
        ...values,
      };
      await surveyHttp.post(`/users/pathway`, body, {
        headers: {
          Authorization: "Bearer " + id,
        },
      });
      dispatch(setAppLoader(false));
      refresh();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <LogoContainer />
              <div className="">
                {/* <div className="font-l">Hello!</div> */}
                <div className="font-l fw-bold mb-5">
                  Provide your details to take the survey
                </div>
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    // pathway: "survivor",
                  }}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    submitHandler(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div>
                            <Input
                              name="firstName"
                              placeholder="First Name"
                              onChange={handleChange}
                              className="singer-input"
                              value={values.firstName}
                              onBlur={handleBlur}
                            />
                            <div className="input-error">
                              {errors.firstName &&
                                touched.firstName &&
                                errors.firstName}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <Input
                              name="lastName"
                              placeholder="Last Name"
                              onChange={handleChange}
                              className="singer-input"
                              value={values.lastName}
                              onBlur={handleBlur}
                            />
                            <div className="input-error">
                              {errors.lastName &&
                                touched.lastName &&
                                errors.lastName}
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <div>
                            <div className="text-secondary font-m fw-medium mb-4">
                              Are you a survivor or caregiver ?
                            </div>
                            <Radio.Group
                              onChange={(e) =>
                                setFieldValue("pathway", e.target.value)
                              }
                              value={values.pathway}
                              className="pathway-selector mb-l"
                            >
                              <Radio.Button
                                value="survivor"
                                className="pathway-choices"
                              >
                                Survivor
                              </Radio.Button>
                              <Radio.Button
                                value="caregiver"
                                className="pathway-choices"
                              >
                                Caregiver
                              </Radio.Button>
                            </Radio.Group>
                            <div className="input-error">
                              {errors.pathway &&
                                touched.pathway &&
                                errors.pathway}
                            </div>
                          </div>
                        </div> */}

                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            height: "55px",
                            borderRadius: "12px",
                            width: "100%",
                          }}
                          className="me-4"
                        >
                          <span className="fw-bold font-ml">
                            Take the Survey
                          </span>
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default ParticipantPathway;
