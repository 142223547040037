import React, { useState } from "react";
import { Tabs } from "antd";
import AdministratorHeader from "./AdministratorHeader";
import AdminListView from "./AdminListView";
import roles from "../../constants/roles";

const { TabPane } = Tabs;

const Administrator: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem("admin-view-tab") || roles["SystemAdmin"]["fe_value"]
  );

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key);
    sessionStorage.setItem("admin-view-tab", key);
  };

  return (
    <div className="cms-wrapper">
      <AdministratorHeader type={selectedTab} />
      <Tabs
        activeKey={selectedTab}
        onChange={tabChangeHandler}
        destroyInactiveTabPane
      >
        <TabPane
          tab={roles["SystemAdmin"]["fe_value"]}
          key={roles["SystemAdmin"]["fe_value"]}
        >
          <AdminListView type={roles["SystemAdmin"]["be_value"]} />
        </TabPane>
        <TabPane
          tab={roles["Monitor"]["fe_value"]}
          key={roles["Monitor"]["fe_value"]}
        >
          <AdminListView type={roles["Monitor"]["be_value"]} />
        </TabPane>
        <TabPane
          tab={roles["ResearchCoordinator"]["fe_value"]}
          key={roles["ResearchCoordinator"]["fe_value"]}
        >
          <AdminListView type={roles["ResearchCoordinator"]["be_value"]} />
        </TabPane>
        <TabPane
          tab={roles["Researcher"]["fe_value"]}
          key={roles["Researcher"]["fe_value"]}
        >
          <AdminListView type={roles["Researcher"]["be_value"]} />
        </TabPane>
        <TabPane
          tab={roles["Observer"]["fe_value"]}
          key={roles["Observer"]["fe_value"]}
        >
          <AdminListView type={roles["Observer"]["be_value"]} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Administrator;
