import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { setParticipantDefaults } from "../../redux/reducers/participantSlice";

const ParticipantUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "screened";
    const search = searchParams.get("search") || "";
    const page = parseInt(searchParams.get("page") || "1") || 1;
    const orderBy = searchParams.get("orderBy") || "asc";
    const sortBy = searchParams.get("sortBy") || "screenId";
    const searchType = searchParams.get("searchType") || "screenId";
    dispatch(
      setParticipantDefaults({
        search,
        page,
        sort: {
          orderBy,
          sortBy,
        },
        searchType,
        type,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(ParticipantUrlLoader);
