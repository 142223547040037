import { Modal, Input, Button } from "antd";
import { Formik } from "formik";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { toggleParticipantLoader } from "../../../redux/reducers/participantSlice";
import { AxiosResponse } from "axios";
// import moment from "moment";

let yup = require("yup");

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  // date: yup.string().required("Please select the baseline visit date"),
});

const AddParticipantModal = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.post(`/users/participant`, values);
      toastMessage("success", res.data.message);
      dispatch(toggleParticipantLoader());
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Add Participant"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <Formik
          initialValues={{
            email: "",
            // date: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div>
                <div>
                  <div>
                    <div className="text-secondary font-m fw-medium mb-1">
                      Email
                    </div>
                    <Input
                      name="email"
                      placeholder="Email..."
                      onChange={handleChange}
                      className="singer-input"
                      value={values.email}
                      onBlur={handleBlur}
                    />
                    <div className="input-error">
                      {errors.email && touched.email && errors.email}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div>
                    <div className="text-secondary font-m fw-medium mb-1">
                      Baseline Visit Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      placeholder="Select Date"
                      value={
                        values.date ? moment(values.date, "DD/MM/YYYY") : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue(`date`, dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.date && touched.date && errors.date}
                    </div>
                  </div>
                </div> */}
                <div className="d-flex mt-3">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">Save</span>
                  </Button>
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddParticipantModal;
