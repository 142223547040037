import { Modal, Table } from "antd";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setModalDetails } from "../../../redux/reducers/modalSlice";

const CRFScoreModal = () => {
  const dispatch = useAppDispatch();
  const { show, qid, phaseId, id, scores, version } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(
      setModalDetails({
        type: "VIEW_CRF_LOG",
        modalProps: {
          show: true,
          qid: qid,
          phaseId: phaseId,
          id: id,
        },
      })
    );
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Score Name",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
      },
    ];
  }, []);

  return (
    <Modal
      title={"CRF Score for version " + version}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <Table
        dataSource={scores}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default CRFScoreModal;
