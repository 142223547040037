import React from "react";
import { DotsVerticalIcon, TrashIcon } from "@heroicons/react/solid";
import { Dropdown, Menu } from "antd";
import { useDrag, useDrop } from "react-dnd";
import { ShuffleIcon } from "../Common/Icons";

type Props = {
  steps: any[];
  handleShuffle: Function;
  deleteStep: Function;
  editable?: boolean;
};
const Forms: React.FC<Props> = ({
  steps,
  handleShuffle,
  deleteStep,
  editable,
}) => {
  return (
    <>
      {steps.length > 0 ? (
        steps.map((step, index) => {
          return (
            <FormItem
              index={index}
              step={step}
              key={step.id}
              handleShuffle={handleShuffle}
              deleteStep={deleteStep}
              editable={editable}
            />
          );
        })
      ) : (
        <div className="no-phase-found">No Steps found</div>
      )}
    </>
  );
};

type ItemProps = {
  step: any;
  index: number;
  handleShuffle: Function;
  deleteStep: Function;
  editable?: boolean;
};

const FormItem: React.FC<ItemProps> = ({
  step,
  index,
  handleShuffle,
  deleteStep,
  editable,
}) => {
  const [, dragRef] = useDrag({
    item: {
      type: "phaseStep",
      sort: true,
      origin: index,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  const [, drop] = useDrop({
    accept: "phaseStep",
    drop: (dropItem: any) => {
      if (dropItem.sort) {
        handleShuffle(dropItem, index);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const menu = () => (
    <Menu style={{ borderRadius: "6px" }}>
      <Menu.Item
        danger
        key="delete"
        onClick={() => {
          deleteStep(step.id);
        }}
      >
        <TrashIcon className="hero-small-icon me-3" />
        <span className="font-sm fw-medium">Remove Step</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div ref={drop}>
      <div className="builder-block" ref={dragRef}>
        {editable && <ShuffleIcon />}
        <div
          className="text-truncate mx-2 font-m fw-medium"
          style={{ minWidth: "0px", flex: 1 }}
        >
          {step?.name}
        </div>
        {editable && (
          <Dropdown overlay={menu} overlayClassName="singer-dropdown">
            <DotsVerticalIcon
              className="hero-icon cp"
              style={{ marginLeft: "auto", color: "#71717A" }}
            />
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Forms;
