import { CogIcon } from "@heroicons/react/solid";
import { Checkbox, DatePicker, Input, Radio, Select, Tooltip } from "antd";
import { useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { setModalDetails } from "../../../../redux/reducers/modalSlice";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./SingleType";

function indxToAlpha(indx: number) {
  const index = indx % 26;
  return String.fromCharCode(65 + index);
}

export const ChoiceType: React.FC<any> = ({ question, showChinese }) => {
  const { fields } = question;

  return (
    <Radio.Group className="preview-choice">
      {fields.map((choice: any, index: number) => (
        <Radio key={choice.id} value={choice.id}>
          {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
            {indxToAlpha(index)}
          </div> */}
          {choice.isOther ? (
            <div className="preview-text-field mb-0 choice-input">
              {choice.isOtherType === "MM/YYYY" ? (
                <Input placeholder={"MM/YYYY"} />
              ) : (
                <Input placeholder={choice.isOtherLabel || "Others, Specify"} />
              )}
            </div>
          ) : (
            <div className="font-sm fw-semibold color-primary choice-text">
              {showChinese ? choice.label_ch || choice.label : choice.label}
            </div>
          )}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export const CheckBoxType: React.FC<any> = ({ question, showChinese }) => {
  const { fields } = question;

  return (
    <Checkbox.Group className="preview-choice">
      {fields.map((choice: any, index: number) => (
        <Checkbox key={choice.id} value={choice.id}>
          {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
            {indxToAlpha(index)}
          </div> */}
          {choice.isOther ? (
            <div className="preview-text-field mb-0 choice-input">
              {choice.isOtherType === "MM/YYYY" ? (
                <Input placeholder={"MM/YYYY"} />
              ) : (
                <Input placeholder={choice.isOtherLabel || "Others, Specify"} />
              )}
            </div>
          ) : (
            <div className="font-sm fw-semibold color-primary choice-text">
              {showChinese ? choice.label_ch || choice.label : choice.label}
            </div>
          )}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export const LikertType: React.FC<any> = ({ question, showChinese }) => {
  const { fields } = question;
  const [selected, setSelected] = useState(null);
  return (
    <div className="preview-likert">
      {fields.map((choice: any) => (
        <div
          key={choice.id}
          onClick={() => setSelected(choice.id)}
          className="likert-option cp"
        >
          <Radio checked={choice.id === selected} />
          <div>
            {showChinese ? choice.label_ch || choice.label : choice.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export const GridType: React.FC<any> = ({ question, showChinese }) => {
  const { properties } = question;
  const { rows, columns } = properties;

  const renderCol = (column: any) => {
    switch (column.type) {
      case "short_text":
        return (
          <Input className="preview-number" placeholder="Type your text here" />
        );
      case "number":
        return (
          <Input
            className="preview-number"
            type="number"
            placeholder="Enter your value here"
          />
        );
      case "date":
        return <DatePicker className="grid-datepicker" />;
      case "checkbox":
        return (
          <Select
            className="grid-selector"
            size="large"
            style={{ textAlign: "left" }}
          >
            {column.options.map((opt: any, index: number) => {
              let text = "";
              if (opt) {
                const split = opt.split("|");
                if (showChinese && split[1]) {
                  text = split[1];
                } else {
                  text = split[0];
                }
              } else {
                text = "Option " + (index + 1);
              }
              return (
                <Select.Option key={"opt" + index} value={opt}>
                  {text}
                </Select.Option>
              );
            })}
          </Select>
        );
      case "score_input":
        return (
          <Input
            className="preview-number"
            type="number"
            placeholder="Type your value here"
          />
        );
    }
  };

  return (
    <table className="grid-preview mb-1">
      <tr>
        <th></th>
        {columns.map((col: any, idx: number) => {
          return (
            <th key={idx}>
              {(showChinese ? col.label_ch || col.label : col.label) ||
                "Column " + (idx + 1)}
            </th>
          );
        })}
      </tr>
      {rows.map((row: any, idx: number) => {
        return (
          <tr key={idx}>
            <td key={"row" + idx}>
              {(showChinese ? row.label_ch || row.label : row.label) ||
                "Row " + (idx + 1)}
            </td>
            {columns.map((col: any, idx: number) => {
              return <td key={"col" + idx}>{renderCol(col)}</td>;
            })}
          </tr>
        );
      })}
    </table>
  );
};

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  date: DateType,
  date_time: DateType,
  upload_file: FileType,
  grid: GridType,
  time: TimeType,
};

export const GroupType: React.FC<any> = ({
  question,
  showModal,
  groupIndex,
  showChinese,
}) => {
  const dispatch = useAppDispatch();

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  const handleSettings = (subIndex: number) => {
    showModal({
      index: groupIndex,
      subIndex: subIndex,
    });
  };
  return (
    <>
      {question.questions.map((q: any, index: number) => {
        const TypeComponent = questionTypes[q.type];
        if (!TypeComponent) {
          //Only Statements will pass this
          return (
            <div className="preview-statement d-flex" key={q.id}>
              <div className="text-prime font-sm fw-medium">
                {showChinese ? q.title_ch || q.title : q.title}
              </div>
              {q.attachment && (
                <span
                  className="ms-3 color-primary cp"
                  onClick={() => viewImage(q.attachment)}
                >
                  View Image
                </span>
              )}
            </div>
          );
        } else {
          return (
            <div className="question-preview-block" key={q.id}>
              <div className="d-flex mb-4 align-items-center">
                {q.questionNo && (
                  <div className="question-number fw-semibold font-m">
                    {q.questionNo}
                  </div>
                )}
                <div className="text-prime fw-semibold font-sm question-title">
                  {showChinese ? q.title_ch || q.title : q.title}
                  {q.required && (
                    <span className="input-block-required">*</span>
                  )}
                </div>
              </div>
              {q.attachment && (
                <div className="question-attachment">
                  <img src={q.attachment.href} alt="question-attachment" />
                </div>
              )}
              <div className="question-action-container">
                <TypeComponent question={q} showChinese={showChinese} />
                {q.remark && (
                  <div className="preview-text-field mt-1">
                    <div className="text-prime font-sm fw-medium mb-2">
                      Enter Remarks
                    </div>
                    <Input
                      placeholder="Type your text here"
                      className="preview-number"
                      maxLength={256}
                    />
                  </div>
                )}
              </div>
              <div
                className="d-flex"
                style={{ justifyContent: "flex-end", width: "100%" }}
              >
                <Tooltip placement="bottom" title="Configuration">
                  <CogIcon
                    className="large-hero-icon cp"
                    style={{ color: "#71717A" }}
                    onClick={() => handleSettings(index)}
                  />
                </Tooltip>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
