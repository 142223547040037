import { PlusIcon } from "@heroicons/react/solid";
import { Button, Dropdown, Menu, Modal } from "antd";
import { useEffect, useState } from "react";
import { getStudyId } from "../../helpers/study";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import Forms from "./Forms";
import Phases from "./Phases";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { canAmendStudy, canExportData } from "../../helpers/roles";
import { AxiosResponse } from "axios";

const confirm = Modal.confirm;

const Study: React.FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const [phases, setPhases] = useState<any[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<string>("");
  const [selectedPhaseName, setSelectedPhaseName] = useState<string>("");
  const [steps, setSteps] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);
  const [phaseToggle, setPhaseToggle] = useState(false);
  const [exportAccess] = useState(canExportData(role));
  const [adminAccess] = useState(canAmendStudy(role));
  const [editAccess, setEditAccess] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    const fetchPhases = async () => {
      try {
        dispatch(setAppLoader(true));
        setSelectedPhase("");
        const studyId = getStudyId();
        const studyRes = await http.get(`/programs/${studyId}`);
        const res = await http.get(`/study/${studyId}/phase`);
        const phases = res.data.data.map((phase: any) => {
          return {
            id: phase.id,
            name: phase.name,
            duration: phase.duration,
          };
        });
        setEditAccess(
          canAmendStudy(role) && studyRes?.data?.data?.status === "draft"
        );
        setStatus(studyRes?.data?.data?.status);
        setPhases(phases);
        if (phases?.[0]?.id) {
          setSelectedPhase(phases?.[0]?.id);
        } else {
          dispatch(setAppLoader(false));
        }
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchPhases();
  }, [
    phaseToggle,
    role,
    dispatch,
    setPhases,
    setSelectedPhase,
    setEditAccess,
    setStatus,
  ]);

  useEffect(() => {
    const fetchStep = async (phaseId: string) => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res = await http.get(`/study/${studyId}/phase/${phaseId}`);
        const phase = res.data.data;
        setSelectedPhaseName(phase.name);
        const newSteps = phase.steps.map((step: any) => {
          return {
            id: step.id,
            name: step?.questionnaire?.name,
          };
        });
        setSteps(newSteps);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    if (selectedPhase) {
      fetchStep(selectedPhase);
    }
  }, [selectedPhase, toggle, dispatch, setSteps]);

  const selectPhase = (phaseId: string) => {
    setSelectedPhase(phaseId);
  };

  const addForms = () => {
    if (!editAccess) {
      return;
    }
    const callback = () => {
      setToggle((prev) => !prev);
    };
    dispatch(
      setModalDetails({
        type: "ADD_PHASE_FORM",
        modalProps: {
          show: true,
          phaseName: selectedPhaseName,
          phaseId: selectedPhase,
          callback: callback,
        },
      })
    );
  };

  const addPhase = () => {
    if (!editAccess) {
      return;
    }
    const callback = () => {
      setPhaseToggle((prev) => !prev);
    };
    dispatch(
      setModalDetails({
        type: "ADD_PHASE_MODAL",
        modalProps: {
          show: true,
          phaseName: "",
          callback: callback,
        },
      })
    );
  };

  const editPhase = (id: string, name: string) => {
    if (!editAccess) {
      return;
    }
    const callback = () => {
      setPhaseToggle((prev) => !prev);
    };
    dispatch(
      setModalDetails({
        type: "ADD_PHASE_MODAL",
        modalProps: {
          show: true,
          phaseName: name,
          phaseId: id,
          callback: callback,
        },
      })
    );
  };

  const deletePhase = (id: string) => {
    if (!editAccess) {
      return;
    }
    const request = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res: AxiosResponse = await http.delete(
          `/study/${studyId}/phase/${id}`
        );
        toastMessage("success", res.data.message);
        setPhaseToggle((prev) => !prev);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    confirm({
      title: "Delete Phase",
      content: "Are you sure you want to delete this phase?",
      onOk() {
        request();
      },
      onCancel() {
        // setButtonDisable(false);
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleStepStuffle = async (item: any, index: number) => {
    if (!editAccess) {
      return;
    }
    const selectedItem = { ...steps[item.origin] };
    const changedSteps = steps.filter((_: any, i: number) => i !== item.origin);

    const prevItems = changedSteps.slice(0, index);
    const nextItems = changedSteps.slice(index, changedSteps.length);

    const modifiedArr = [...prevItems, selectedItem, ...nextItems];
    const shuffleObj: any = {};
    modifiedArr.forEach((step, index) => {
      shuffleObj[step.id] = index + 1;
    });
    try {
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      await http.post(
        `study/${studyId}/phase/${selectedPhase}/order`,
        shuffleObj
      );
      setSteps(modifiedArr);
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const deleteStep = async (stepId: string) => {
    if (!editAccess) {
      return;
    }
    try {
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      await http.delete(
        `study/${studyId}/phase/${selectedPhase}/step/${stepId}`
      );
      setToggle((toggle) => !toggle);
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const exportData = async (type: string) => {
    try {
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      let addon = "";
      let body: any = {};
      switch (type) {
        case "food":
          addon = "export-food";
          break;
        case "dictionary":
          addon = "dictionary";
          break;
        case "medication":
          addon = "export-medication";
          break;
        case "export-medical-history":
          addon = "export-medical-history";
          break;
        case "incident":
          addon = "export-incident";
          break;
        // case "survivor":
        //   addon = "export";
        //   body = {
        //     questionnaireIds: [],
        //     participantIds: [],
        //     pathway: "survivor",
        //   };
        //   break;
        // case "caregiver":
        //   addon = "export";
        //   body = {
        //     questionnaireIds: [],
        //     participantIds: [],
        //     pathway: "caregiver",
        //   };
        //   break;
        case "study":
          addon = "export";
          body = {
            questionnaireIds: [],
            participantIds: [],
          };
          break;
        case "screening":
          addon = "export-screening";
          break;
        case "epro":
          addon = "export-epro";
          break;
      }
      if (!addon) {
        dispatch(setAppLoader(false));
        return;
      }
      const res = await http.post(`/study/${studyId}/phase/${addon}`, body);
      const { jobId } = res.data.data;
      dispatch(
        setModalDetails({
          type: `DOWNLOAD_MODAL`,
          modalProps: {
            show: true,
            jobId: jobId,
          },
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const menu = () => (
    <Menu>
      {/* <Menu.Item key="2" onClick={() => exportData("food")}>
        Export Food
      </Menu.Item> */}
      <Menu.Item key="3" onClick={() => exportData("dictionary")}>
        Export Data Dictionary
      </Menu.Item>
      <Menu.Item key="7" onClick={() => exportData("study")}>
        Export Study
      </Menu.Item>
      {/* <Menu.Item key="4" onClick={() => exportData("survivor")}>
        Export Survivor Data
      </Menu.Item>
      <Menu.Item key="4" onClick={() => exportData("caregiver")}>
        Export Caregiver Data
      </Menu.Item> */}
      {/* <Menu.Item key="5" onClick={() => exportData("screening")}>
        Export Screening Data
      </Menu.Item> */}
      {/* <Menu.Item key="6" onClick={() => exportData("epro")}>
        Export Participant Diary
      </Menu.Item> */}
      {/* <Menu.Item key="0" onClick={() => exportData("incident")}>
        Export Adverse Events
      </Menu.Item> */}
      {/* <Menu.Item key="1" onClick={() => exportData("medication")}>
        Export Medication
      </Menu.Item> */}
      {/* <Menu.Item key="7" onClick={() => exportData("export-medical-history")}>
        Export Medical History
      </Menu.Item> */}
    </Menu>
  );

  const handleSignoff = () => {
    const callback = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res = await http.post(`programs/${studyId}/status`, {
          status: "active",
        });
        toastMessage("success", res.data.message);
        setPhaseToggle((pre) => !pre);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    confirm({
      title: "Go Live?",
      content:
        "Are you sure you'd like to make the study Live? This action is irreversible and further edits to the study will not be possible",
      onOk() {
        callback();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  return (
    <div className="edit-study-layout">
      <div className="content-header">
        <div className="font-m fw-bold font-l ms-5 text-secondary">
          BE-READI Study
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        {adminAccess && status === "draft" && (
          <Button
            type="primary"
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={handleSignoff}
          >
            <span className="fw-semibold font-sm">Go Live</span>
          </Button>
        )}
        {exportAccess && (
          <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
            <Button
              type="primary"
              className="d-flex align-items-center me-4"
              style={{ height: "40px", borderRadius: "12px" }}
            >
              <span className="fw-semibold font-sm">Export</span>
            </Button>
          </Dropdown>
        )}
      </div>
      <div className="d-flex h-100 p-5">
        <div
          className="division builder-shadow me-5 overflow-hover"
          style={{ maxWidth: "35%" }}
        >
          <div className="division-header">
            <div className="text-prime font-ml fw-bold">Study Phase</div>
            {editAccess && (
              <div>
                <Button
                  style={{ borderRadius: "12px", height: "32px" }}
                  className="d-flex align-items-center"
                  onClick={addPhase}
                >
                  <PlusIcon className="hero-small-icon me-2" />
                  <span className="font-xs fw-semibold">Add Phase</span>
                </Button>
              </div>
            )}
          </div>
          <Phases
            editable={editAccess}
            phases={phases}
            selectedPhase={selectedPhase}
            selectPhase={selectPhase}
            editPhase={editPhase}
            deletePhase={deletePhase}
          />
        </div>
        <div className="division builder-shadow overflow-hover">
          <div className="division-header">
            <div className="text-prime font-ml fw-bold">
              {selectedPhaseName ? `Steps of ${selectedPhaseName}` : "Steps"}
            </div>
            {selectedPhase && editAccess && (
              <div>
                <Button
                  style={{ borderRadius: "12px", height: "32px" }}
                  className="d-flex align-items-center"
                  onClick={addForms}
                >
                  <PlusIcon className="hero-small-icon me-2" />
                  <span className="font-xs fw-semibold">Add Forms</span>
                </Button>
              </div>
            )}
          </div>
          <Forms
            steps={steps}
            handleShuffle={handleStepStuffle}
            deleteStep={deleteStep}
            editable={editAccess}
          />
        </div>
      </div>
    </div>
  );
};

const StudyWrapper: React.FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Study />
    </DndProvider>
  );
};

export default StudyWrapper;
