import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DiaryState {
  foodList: any[];
  foodCount: number;
  exerciseList: any[];
  exerciseCount: number;
  toggleLoader: boolean;
  searchTerm: string;
  selectedFood: any;
  servingsList: any[];
  servingsCount: number;
  servingCategories: any[];
  foodCategories: any[];
}

const initialState: DiaryState = {
  foodList: [],
  foodCount: 0,
  exerciseList: [],
  exerciseCount: 0,
  toggleLoader: false,
  searchTerm: "",
  servingsList: [],
  servingsCount: 0,
  selectedFood: {
    ids: [],
    items: [],
  },
  servingCategories: [],
  foodCategories: [],
};

export const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {
    setFoodDetails: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.foodList = action.payload.data;
      state.foodCount = action.payload.count;
    },
    setExerciseDetails: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.exerciseList = action.payload.data;
      state.exerciseCount = action.payload.count;
    },
    toggleDiaryLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setDiarySearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSelectedFood: (state, action: PayloadAction<any>) => {
      state.selectedFood = action.payload;
    },
    setServingsList: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      state.servingsList = action.payload.data;
      state.servingsCount = action.payload.count;
    },
    setServingCategories: (state, action: PayloadAction<{ data: any[] }>) => {
      state.servingCategories = action.payload.data;
    },
    setFoodCategories: (state, action: PayloadAction<{ data: any[] }>) => {
      state.foodCategories = action.payload.data;
    },
  },
});

export const {
  setFoodDetails,
  setExerciseDetails,
  toggleDiaryLoader,
  setDiarySearchTerm,
  setSelectedFood,
  setServingsList,
  setServingCategories,
  setFoodCategories,
} = diarySlice.actions;

export default diarySlice.reducer;
