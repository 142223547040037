import { Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import BranchLogic from "../QuestionBuilder/BranchLogic";
import { QuestionLabelMap } from "../QuestionBuilder/questionTypes";
import { BasicQuestionPropTab } from "../QuestionBuilder/QuestionPropertiesModal";
import ResponseLogic from "../QuestionBuilder/ResponseLogic";

const { TabPane } = Tabs;

type Props = {
  questions: any;
  modalProp: any;
  setModal: Function;
};

const QuestionPropertiesPreview: React.FC<Props> = ({
  questions,
  modalProp,
  setModal,
}) => {
  const [question, setQuestion] = useState<any>(null);
  const [showResponseCode, setResponseCode] = useState(false);
  const [showLogicJump, setLogicJump] = useState(false);

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    let question = questions[modalProp.index];
    if (modalProp.subIndex !== undefined) {
      question = question["questions"][modalProp.subIndex];
    }
    const cloned = JSON.parse(JSON.stringify(question));
    if (cloned?.branches?.length > 0) {
      setLogicJump(true);
    }
    if (cloned?.properties?.responseCodes?.length > 0) {
      const show = cloned?.properties?.responseCodes?.some?.(
        (field: any) => field.code
      );
      setResponseCode(show);
    } else if (cloned?.fields?.length > 0) {
      const show = cloned?.fields?.some?.((field: any) => field.code);
      setResponseCode(show);
    }
    setQuestion(cloned);
  }, [modalProp, questions, setQuestion, setLogicJump, setResponseCode]);

  const modifyQuestion = () => {};
  return (
    <Modal
      width="725px"
      footer={null}
      onCancel={closeModal}
      visible={true}
      className="question-settings-modal"
    >
      <div className="fw-semibold font-ml text-secondary mb-4">
        {question ? QuestionLabelMap[question.type] : "Question"}
      </div>
      <Tabs style={{ minHeight: "400px" }}>
        <TabPane tab="Basic" key="basic">
          {question && (
            <BasicQuestionPropTab
              question={question}
              preview
              modifyQuestion={modifyQuestion}
            />
          )}
        </TabPane>
        {showResponseCode && (
          <TabPane tab="Response code" key="Response code">
            <ResponseLogic
              question={question}
              modifyQuestion={modifyQuestion}
              preview
            />
          </TabPane>
        )}
        {showLogicJump && (
          <TabPane tab="Logic Jump" key="logic jump">
            <BranchLogic
              question={question}
              modifyQuestion={modifyQuestion}
              questions={questions}
              preview
            />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default QuestionPropertiesPreview;
