// import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  // Badge,
  //  Dropdown,
  Input,
  //  Menu
} from "antd";
import React from "react";
// import { useAppDispatch } from "../../../redux/hooks";
// import { setModalDetails } from "../../../redux/reducers/modalSlice";
// import { QueryIcon } from "../../Common/Icons";
import {
  ChoiceType,
  GroupType,
  CheckBoxType,
  LikertType,
  GridType,
} from "./ChoiceType";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./SingleType";

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  grid: GridType,
  date: DateType,
  date_time: DateType,
  group: GroupType,
  upload_file: FileType,
  time: TimeType,
};

type Props = {
  question: any;
  index: number;
  answers: any;
  saveAnswer: Function;
  hideQuestions: any[];
  showChinese: boolean;
  selectedQuestionniare: any;
  userId: any;
  // fetchQueries: Function;
  // queries: any[];
};

const QuestionBlock: React.FC<Props> = ({
  question,
  index,
  saveAnswer,
  answers,
  hideQuestions,
  showChinese,
  selectedQuestionniare,
  userId,
  // fetchQueries,
  // queries,
}) => {
  // const dispatch = useAppDispatch();
  const TypeComponent = questionTypes[question.type];

  if (!TypeComponent) {
    return null;
  }

  const { id } = question;
  const answer = answers[id];
  // const query = queries[id];

  const saveRemark = (event: any) => {
    if (answer) {
      saveAnswer(id, {
        ...answer,
        remarkValue: event.target.value,
      });
    } else {
      saveAnswer(id, {
        remarkValue: event.target.value,
      });
    }
  };

  // const openQuery = (question: any) => {
  //   dispatch(
  //     setModalDetails({
  //       type: "SHOW_QUERY",
  //       modalProps: {
  //         show: true,
  //         selectedQuestionniare: selectedQuestionniare,
  //         questionId: question.id,
  //         userId: userId,
  //         fetchQueries: fetchQueries,
  //         title: question.title,
  //       },
  //     })
  //   );
  // };

  // const viewQuery = (question: any) => {
  //   dispatch(
  //     setModalDetails({
  //       type: "VIEW_QUERY",
  //       modalProps: {
  //         show: true,
  //         userId: userId,
  //         fetchQueries: fetchQueries,
  //         title: question.title,
  //         queries: query,
  //       },
  //     })
  //   );
  // };

  // const commentQuery = (question: any) => {
  //   dispatch(
  //     setModalDetails({
  //       type: "QUERY_COMMENT",
  //       modalProps: {
  //         show: true,
  //         title: question.title,
  //         queries: query,
  //       },
  //     })
  //   );
  // };

  // const menu = (question: any) => (
  //   <Menu>
  //     <Menu.Item key="1" onClick={() => openQuery(question)}>
  //       Add Query
  //     </Menu.Item>
  //     {query && (
  //       <>
  //         <Menu.Item key="2" onClick={() => viewQuery(question)}>
  //           View Queries
  //         </Menu.Item>
  //         <Menu.Item key="3" onClick={() => commentQuery(question)}>
  //           View Comments
  //         </Menu.Item>
  //       </>
  //     )}
  //   </Menu>
  // );

  return (
    <div className="crf-question-block" key={question.id}>
      <div className="d-flex mb-2 align-items-center">
        {question.questionNo && (
          <div className="question-number fw-semibold font-m">
            {question.questionNo}
          </div>
        )}
        <div className="text-prime fw-semibold font-sm question-title">
          {showChinese ? question.title_ch || question.title : question.title}
          {question.required && <span className="input-block-required">*</span>}
        </div>
        {/* {question.type !== "group" && (
          <>
            {query &&
              query?.filter((q: any) => q.status !== "closed").length > 0 && (
                <Badge
                  count={query.filter((q: any) => q.status !== "closed").length}
                  className="me-3"
                >
                  <QueryIcon />
                </Badge>
              )}
            <Dropdown
              overlay={() => menu(question)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <DotsVerticalIcon
                className="hero-icon cp"
                style={{ color: "#71717A", marginLeft: "auto" }}
              />
            </Dropdown>
          </>
        )} */}
      </div>
      {question.description && (
        <div className="crf-text-field mt-1" style={{ marginLeft: "50px" }}>
          <div className="text-prime font-sm fw-medium mb-2">
            {question.description}
          </div>
        </div>
      )}
      {question.attachment && (
        <div className="question-attachment">
          <img src={question.attachment.href} alt="question-attachment" />
        </div>
      )}
      <div className="question-action-container">
        <TypeComponent
          question={question}
          groupIndex={index}
          saveAnswer={saveAnswer}
          hideQuestions={hideQuestions}
          answer={question.type === "group" ? answers : answer}
          // query={question.type === "group" ? queries : query}
          showChinese={showChinese}
          selectedQuestionniare={selectedQuestionniare}
          userId={userId}
          // fetchQueries={fetchQueries}
        />
        {question.remark && (
          <div className="crf-text-field mt-1">
            <div className="text-prime font-sm fw-medium mb-2">
              Enter Remarks
            </div>
            <Input
              placeholder="Type your text here"
              className="crf-number"
              maxLength={256}
              value={answer?.remarkValue}
              onChange={saveRemark}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionBlock;
