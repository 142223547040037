import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { getStudyId } from "../../../helpers/study";
import http from "../../../http";

const CRFExportModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const [phases, setPhases] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>(["all"]);

  const { show, participants } = useAppSelector(
    (state) => state.modal.modalProps
  );

  useEffect(() => {
    const fetchPhases = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res = await http.get(`/study/${studyId}/phase`);
        let phases: any[] = [];
        res.data.data.forEach((phase: any) => {
          phases.push({
            id: phase.id,
            name: phase.name,
            steps: phase.steps.map((step: any) => {
              return {
                id: step.id,
                name: step?.questionnaire?.name,
                questionnaireId: step?.questionnaireId,
              };
            }),
          });
        });
        setPhases(phases);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err);
        dispatch(setAppLoader(false));
      }
    };
    fetchPhases();
  }, [dispatch, setPhases]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (keys: any[]) => {
    const newKeys = [...keys];
    if (newKeys.length > 1 && newKeys.includes("all")) {
      setSelected(newKeys.filter((key) => key !== "all"));
    } else {
      setSelected(newKeys);
    }
  };

  const submitHandler = async () => {
    try {
      if (selected.length < 1) {
        toastMessage("warning", "Select atleast one form to export");
        return;
      }
      dispatch(setAppLoader(true));
      let ids = selected.map((val: string) => {
        if (val !== "all") {
          const split = val.split("#");
          return {
            phaseId: split[0],
            // pivotId: split[1],
            questionnaireId: split[2],
          };
        } else {
          return "all";
        }
      });
      if (ids[0] === "all") {
        ids = [];
      }
      const studyId = getStudyId();
      const res = await http.post(`/study/${studyId}/phase/export`, {
        questionnaireIds: ids,
        participantIds: participants,
      });
      const { jobId } = res.data.data;
      dispatch(
        setModalDetails({
          type: `DOWNLOAD_MODAL`,
          modalProps: {
            show: true,
            jobId: jobId,
          },
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <Modal
      title="Export"
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div>
        <div className="text-secondary font-m mb-4">
          <span>No of participants selected:</span>
          <span className="ms-2 fw-medium">{participants?.length}</span>
        </div>
        <div>
          <Select
            size="large"
            mode="multiple"
            placeholder="Select forms to export"
            className="mt-2 custom-select-field"
            value={selected}
            onChange={changeHandler}
          >
            <Select.Option value="all" key="all">
              All
            </Select.Option>
            {phases.map((phase) => {
              return (
                <Select.OptGroup label={phase.name} key={phase.id}>
                  {phase.steps.map((step: any) => {
                    return (
                      <Select.Option
                        value={
                          phase.id + "#" + step.id + "#" + step.questionnaireId
                        }
                        key={step.id}
                      >
                        {step.name}
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })}
          </Select>
        </div>
        <div className="mt-5">
          <Button
            onClick={submitHandler}
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            type="primary"
          >
            <span className="fw-semibold font-sm">Export</span>
          </Button>
          <Button
            onClick={closeHandler}
            style={{ height: "40px", borderRadius: "12px" }}
            htmlType="button"
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CRFExportModal;
